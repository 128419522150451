import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { FaCheck } from "react-icons/fa6";
import { createSubscription } from "../utils/apis/subscriptionService";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUser } from "../redux/slices/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Stripe initialization
const stripePromise = loadStripe("pk_test_51QKfxKBV7cs4OT98DTH5t3cZ7xsZWnQepUU6izFRZ3ycDwLbw5SbsQXpIKorDzNfxE22SX0mm0UmZ9p3iOGUD9Sn00TdyeGp4D");

const features = [
  "Unlimited Number of Size infographic Creation",
  "Unlimited Number of Condition infographic Creation",
  "Set Default templates for Size and Condition infographics",
  "Cancel Subscription anytime",
  "Access to all our exclusive features",
  "Access to our customer support team",
];

const CheckoutForm = ({ onPaymentMethodCreated }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: "Customer Name", // Replace with actual customer name
        },
      });

      if (error) {
        console.error("Error creating payment method:", error);
        setError(error.message);
      } else {
        console.log("Payment Method created:", paymentMethod);

        // Call the parent function to handle subscription creation
        if (onPaymentMethodCreated) {
          onPaymentMethodCreated(paymentMethod.id); // Pass paymentMethod.id to the parent component
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#6B7280",
        fontSize: "16px",
        fontFamily: "Arial, sans-serif",
        "::placeholder": {
          color: "#A0AEC0",
        },
      },
      invalid: {
        color: "#E53E3E",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-gray-100 p-4 rounded-md shadow-md">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {error && <div className="text-red-500 text-sm">{error}</div>}
      <button
        type="submit"
        disabled={!stripe}
        className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 px-4 rounded-md shadow-lg disabled:opacity-50"
      >
        Start Free Trial
      </button>
    </form>
  );
};

function CheckoutPage({ planId, userId }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubscription = async (paymentMethodId) => {
    setIsSubmitting(true); // Show the spinner
    setError(null);

    try {
      console.log("paymentMethodId::", paymentMethodId);
      const subscriptionResponse = await createSubscription(
        userId, // Pass the userId for the subscription
        planId, // Pass the selected plan ID
        paymentMethodId // Pass the payment method ID
      );

      toast.success("Subscription Created Successfully");

      // Fetch user data after successful subscription creation
      dispatch(fetchUser()); // Fetch user data

      // Reload the page after fetching the user data
      setIsSubmitting(false); // Hide the spinner
      navigate("/history-timeline");
    } catch (err) {
      console.error("Error creating subscription:", err);
      setError(err.message || "Failed to create subscription");
      setIsSubmitting(false); // Hide the spinner
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column - Subscription Details */}
            <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
              <h2 className="text-2xl font-semibold text-indigo-700">Confirm Subscription Extension</h2>
              <p className="text-gray-600 mt-2">The following plan will be added to your subscription:</p>
              <p className="text-gray-600 mt-4">Start your free 7-day trial for Illuscriber.</p>
              <div className="mt-6 space-y-6">
                <div className="text-lg font-semibold text-indigo-700">Standard Subscription - $25/month</div>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="text-lg font-semibold mb-4 text-indigo-700">Standard Subscription Features</h3>
                  <ul className="space-y-3">
                    {features.map((feature, index) => (
                      <li key={index} className="flex items-start">
                        <FaCheck className="h-5 w-5 mr-2 text-indigo-700" />
                        <span className="text-sm text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Right Column - Payment Details */}
            <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
              <h2 className="text-2xl font-semibold text-indigo-700">Payment Detail</h2>
              <p className="text-gray-600 mt-2">Enter your payment details below to activate the monthly subscription.</p>
              <CheckoutForm onPaymentMethodCreated={handleSubscription} />
              {error && <p className="text-red-500 text-sm mt-4">{error}</p>}

              {/* Spinner */}
              {isSubmitting && (
                <div className="flex justify-center mt-6">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
                </div>
              )}

              <div className="text-sm text-gray-600 space-y-2 mt-6">
                <p>Cancel anytime.</p>
                <p>Your subscription will be charged on the 8th day, after your trial ends.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Elements>
  );
}

export default CheckoutPage;


// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// const CheckoutForm = ({ amount }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [error, setError] = useState(null);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);

//     try {
//       const { token } = await stripe.createToken(cardElement);
//       console.log("cardElement:",cardElement,token)
     
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <CardElement />
//       <button type="submit" disabled={!stripe}>
//         Pay ${amount}
//       </button>
//       {error && <div>{error}</div>}
//     </form>
//   );
// };
// export default CheckoutForm;
// import React, { useState, useEffect } from "react";
// import { loadStripe } from "@stripe/stripe-js"; // Load Stripe outside of the component
// import { Elements } from "@stripe/react-stripe-js";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
// import { FaCreditCard } from "react-icons/fa";
// import { useSelector } from "react-redux"; // Import useSelector

// // Load the Stripe publishable key
// const stripePromise = loadStripe("pk_test_51QKfxKBV7cs4OT98DTH5t3cZ7xsZWnQepUU6izFRZ3ycDwLbw5SbsQXpIKorDzNfxE22SX0mm0UmZ9p3iOGUD9Sn00TdyeGp4D");

// // Define the Product ID (plan ID)
// const PLAN_ID = "prod_REfIX6Q0t2zEDg";

// const CARD_ELEMENT_OPTIONS = {
//   style: {
//     base: {
//       color: "#6B7280", // Default text color (can be any color you want)
//       "::placeholder": {
//         color: "#A0AEC0", // Placeholder text color
//       },
//       ":focus": {
//         color: "#6B21A8", // Purple text color on focus
//       },
//     },
//     invalid: {
//       color: "#E53E3E", // Color for invalid input
//     },
//   },
// };

// function CheckoutForm() {
//   const [paymentOption, setPaymentOption] = useState(null);
//   const [savedCardDetails, setSavedCardDetails] = useState(null);
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
//   const [error, setError] = useState(null);
//   const stripe = useStripe();
//   const elements = useElements();

//   // Use useSelector to get the user state from the Redux store
//   const user = useSelector((state) => state.auth.user); // Access the user directly from the auth slice

//   const handleSelectChange = (selectedOption) => {
//     setPaymentOption(selectedOption);
//   };

//   const createSubscriptionHandler = async (event) => {
//     event.preventDefault(); // Prevent default form submission (no page refresh)

//     // Log to the console for debugging
//     console.log("Form submission started...");
//     console.log("Payment Option:", paymentOption);
//     console.log("User Info:", user);

//     setIsButtonDisabled(true);
//     try {
//       let paymentMethodId;

//       // Check if the payment option is 'newCard' or no saved card selected
//       if (paymentOption === "newCard" || !savedCardDetails) {
//         const cardElement = elements?.getElement(CardElement);
//         if (!cardElement) throw new Error("Card details not available.");
//         const paymentMethodResult = await stripe?.createPaymentMethod({
//           type: "card",
//           card: cardElement,
//           billing_details: { name: user.name, email: user.email },
//         });
//         if (paymentMethodResult?.error) throw new Error(paymentMethodResult.error.message);
//         paymentMethodId = paymentMethodResult.paymentMethod.id;

//         // Log the result of payment method creation
//         console.log("Payment Method ID:", paymentMethodId);
//       } else {
//         const selectedCard = savedCardDetails.find((card) => card.id === paymentOption);
//         paymentMethodId = selectedCard ? selectedCard.id : null;
//       }

//       // Log the data being sent to the backend
//       if (paymentMethodId) {
//         const data = {
//           ...user,
//           paymentMethod: paymentMethodId,
//           priceId: PLAN_ID, // Use the constant PLAN_ID here
//           isNewCustomer: !user.customerId,
//           referralId: "VITE_TOLT_REFERRAL", // Replace with actual referral ID if needed
//         };
//         console.log("Subscription Data:", data);

//         // Here, instead of sending to backend, just log the data
//         // await createSubscription(data);
//         // localStorage.setItem("subscription", "true");
//       } else {
//         console.error("Payment method ID not found");
//       }
//     } catch (error) {
//       setIsButtonDisabled(false);
//       setError(error.message || "An error occurred.");
//       console.error("Error Message:", error);
//     }
//   };

//   return (
//     <Elements stripe={stripePromise}>
//       <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
//         <div className="max-w-6xl mx-auto">
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//             <div className="bg-white shadow-lg rounded-lg p-6 border">
//               <h2 className="text-2xl font-semibold text-indigo-700">Confirm Subscription</h2>
//               <p className="text-gray-600 mt-2">Start your free 7-day trial for the selected plan.</p>
//               <div className="mt-6 space-y-6">
//                 <div className="text-lg font-semibold text-indigo-700">Standard Subscription - $25/month</div>
//                 <div className="bg-white p-6 rounded-lg border">
//                   <h3 className="text-lg font-semibold mb-4 text-indigo-700">Standard Subscription Features</h3>
//                   {/* Add features list */}
//                 </div>
//               </div>
//             </div>

//             <div className="bg-white shadow-lg rounded-lg p-6 border">
//               <h2 className="text-2xl font-semibold text-indigo-700">Payment Detail</h2>
//               <form onSubmit={createSubscriptionHandler} className="mt-6 space-y-6">
//                 {error && <p className="text-red-500 text-sm">{error}</p>}
//                 {savedCardDetails ? (
//                   <div className="my-4">
//                     <label htmlFor="payment-option" className="text-sm font-medium">Select Payment Method</label>
//                     <select
//                       id="payment-option"
//                       value={paymentOption}
//                       onChange={(e) => handleSelectChange(e.target.value)}
//                       className="text-sm w-full p-2 border border-gray-300 rounded-md"
//                     >
//                       {savedCardDetails.map((card, index) => (
//                         <option value={card.id} key={index}>
//                           **** **** **** {card.last4}
//                         </option>
//                       ))}
//                       <option value="newCard">Add New Card</option>
//                     </select>

//                     {paymentOption !== "newCard" && (
//                       <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-4 m-4 relative">
//                         <div className="absolute top-4 right-4 text-xl">
//                           <FaCreditCard />
//                         </div>
//                         <div className="px-6 py-4">
//                           <div className="font-bold text-xl mb-2">
//                             **** **** **** {savedCardDetails.find((card) => card.id === paymentOption)?.last4}
//                           </div>
//                           <p>Expires: {savedCardDetails.find((card) => card.id === paymentOption)?.exp_month}/{savedCardDetails.find((card) => card.id === paymentOption)?.exp_year}</p>
//                         </div>
//                       </div>
//                     )}

//                     {paymentOption === "newCard" && (
//                       <div className="mt-4">
//                         <CardElement options={CARD_ELEMENT_OPTIONS} />
//                       </div>
//                     )}
//                   </div>
//                 ) : (
//                   <CardElement options={CARD_ELEMENT_OPTIONS} />
//                 )}

//                 <button
//                   type="submit"
//                   disabled={isButtonDisabled}
//                   className="w-full py-3 text-white rounded-md font-semibold bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//                 >
//                   {isButtonDisabled ? "Processing..." : "Start Free Trial"}
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Elements>
//   );
// }

// export default CheckoutForm;
// import * as React from "react";
// import { loadStripe } from "@stripe/stripe-js"; // Load Stripe outside of the component
// import { Elements } from "@stripe/react-stripe-js";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
// import { FaCheck } from "react-icons/fa6";
// import { createSubscription } from "../utils/apis/subscriptionService";

// // Make sure to use your actual publishable key
// const stripePromise = loadStripe("pk_test_XXXXXXXXXXXXXXXXXXXX");

// const features = [
//   "Unlimited Number of Size infographic Creation",
//   "Unlimited Number of Condition infographic Creation",
//   "Set Default templates for Size and Condition infographics",
//   "Cancel Subscription anytime",
//   "Access to all our exclusive features",
//   "Access to our customer support team",
// ];

// export default function CheckoutForm({ planId, userId }) {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [isSubmitting, setIsSubmitting] = React.useState(false);
//   const [error, setError] = React.useState(null);

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsSubmitting(true);
//     setError(null);

//     if (!stripe || !elements) {
//       setError("Stripe has not loaded yet. Please try again later.");
//       setIsSubmitting(false);
//       return;
//     }

//     const cardElement = elements.getElement(CardElement);
//     console.log(":cardElement:",cardElement)
//     if (cardElement) {
//       const { error, paymentMethod } = await stripe.createPaymentMethod({
//         type: "card",
//         card: cardElement,
//         billing_details: {
//           name: "Customer Name", // Replace with actual customer name
//         },
//       });

//       if (error) {
//         console.error("Error creating payment method:", error);
//         setError(error.message);
//         setIsSubmitting(false);
//       } else {
//         console.log("Payment Method created:", paymentMethod);

//         // Proceed with backend subscription creation using `paymentMethod.id`
//         try {
//           const subscriptionResponse = await createSubscription(
//             userId, // Pass the userId for the subscription
//             planId, // Pass the selected plan ID
//             paymentMethod.id // Pass the payment method ID
//           );

//           console.log("Subscription created successfully:", subscriptionResponse);
//           setIsSubmitting(false);
//           // Example: navigate("/subscription-success"); // Redirect to success page if needed
//         } catch (err) {
//           console.error("Error creating subscription:", err);
//           setError(err.message || "Failed to create subscription");
//           setIsSubmitting(false);
//         }
//       }
//     }
//   };

//   const CARD_ELEMENT_OPTIONS = {
//     style: {
//       base: {
//         color: "#6B7280",
//         "::placeholder": {
//           color: "#A0AEC0",
//         },
//       },
//       invalid: {
//         color: "#E53E3E",
//       },
//     },
//   };

//   return (
//     <Elements stripe={stripePromise}>
//       <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
//         <div className="max-w-6xl mx-auto">
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//             {/* Left Column - Subscription Details */}
//             <div className="bg-white shadow-lg rounded-lg p-6 border">
//               <h2 className="text-2xl font-semibold text-indigo-700">Confirm Subscription Extension</h2>
//               <p className="text-gray-600 mt-2">The following plan will be added to your subscription:</p>
//               <p className="text-gray-600">Start your free 7-day trial for Illuscriber.</p>
//               <div className="mt-6 space-y-6">
//                 <div className="text-lg font-semibold text-indigo-700">Standard Subscription - $25/month</div>
//                 <div className="bg-white p-6 rounded-lg border">
//                   <h3 className="text-lg font-semibold mb-4 text-indigo-700">Standard Subscription Features</h3>
//                   <ul className="space-y-3">
//                     {features.map((feature, index) => (
//                       <li key={index} className="flex items-start">
//                         <FaCheck className="h-5 w-5 mr-2 text-indigo-700" />
//                         <span className="text-sm text-gray-600">{feature}</span>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>

//             {/* Right Column - Payment Details */}
//             <div className="bg-white shadow-lg rounded-lg p-6 border">
//               <h2 className="text-2xl font-semibold text-indigo-700">Payment Detail</h2>
//               <p className="text-gray-600 mt-2">
//                 Enter your payment details below to activate the monthly subscription.
//               </p>
//               <form onSubmit={handleSubmit} className="mt-6 space-y-6">
//                 {error && <p className="text-red-500 text-sm">{error}</p>}
//                 <div className="relative">
//                   <CardElement
//                     options={CARD_ELEMENT_OPTIONS}
//                     className="border border-gray-300 focus:border-indigo-600 px-3 py-3 rounded-md outline-none"
//                   />
//                 </div>
//                 <button
//                   type="submit"
//                   disabled={isSubmitting}
//                   className="w-full py-3 text-white rounded-md font-semibold bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
//                 >
//                   {isSubmitting ? "Processing..." : "Start Free Trial"}
//                 </button>
//                 <div className="text-sm text-gray-600 space-y-2">
//                   <p>Cancel anytime.</p>
//                   <p>Your subscription will be charged on the 8th day, after your trial ends.</p>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Elements>
//   );
// }
