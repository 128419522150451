import React from "react";
import UpdateCard from "../../stripe/UpdateCard";
import Invoices from "../../stripe/Invoices";
import CancelSubscription from "../../stripe/CancelSubscription";
import SubscriptionDetails from "../../stripe/SubscriptionDetails";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutPage from "../../stripe/CheckoutForm";

// Load Stripe instance
const stripePromise = loadStripe("pk_test_51QKfxKBV7cs4OT98DTH5t3cZ7xsZWnQepUU6izFRZ3ycDwLbw5SbsQXpIKorDzNfxE22SX0mm0UmZ9p3iOGUD9Sn00TdyeGp4D");

const Subscription = () => {
  // Get user data from Redux store
  const { user } = useSelector((state) => state.auth);

  // Check if the user has a subscription and if it's active
  const hasActiveSubscription = user?.subscription?.status === "active";
  const trialEndDate = user?.subscription?.trial_end;
  const currentDate = new Date();

  // If trialEndDate or subscription is undefined, show checkout page
  if (!trialEndDate || user?.subscription?.id === undefined || user?.subscription?.status == "canceled") {
    return (
      <div className="col-span-6 bg-white border-l px-5">
        <Elements stripe={stripePromise}>
          <CheckoutPage planId={"prod_REgn9n0Zyi1zRp"} />
        </Elements>
      </div>
    );
  }

  // Convert trial end date to a Date object
  const trialEnd = new Date(trialEndDate);

  // Check if the trial has ended or if the subscription has expired
  const isTrialExpired = currentDate > trialEnd;
  const isSubscriptionCreated = user?.subscription?.id !== null;

  // Render the component based on the subscription state
  return (
    <div className="p-1">
      <h1 className="text-2xl font-semibold mb-4">Subscription</h1>
      <p className="mb-6">Manage your subscription plans and billing here.</p>

      {/* Show subscription management components if trial is active or subscription is active */}
      {isSubscriptionCreated && !isTrialExpired ? (
        <>
          {/* Row with both Update and Cancel buttons */}
          <div className="flex gap-4 mt-4">
            <div className="flex-1">
              <SubscriptionDetails userId={user?.id} />
            </div>
            <div className="flex-1">
           
            <Elements stripe={stripePromise}>   <UpdateCard  subscriptionId={user?.subscription?.id}/>    </Elements>
            </div>
            <div className="flex-1">
              <CancelSubscription  subscriptionId={user?.subscription?.id}/>
            </div>
          </div>

          {/* <Invoices userId={user?.id}/> */}
        </>
      ) : (
        // Show checkout page if no active subscription or trial expired
        <div className="col-span-6 bg-white border-l px-5">
          <Elements stripe={stripePromise}>
            <CheckoutPage planId={"prod_REgn9n0Zyi1zRp"} />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default Subscription;
// // src/pages/Admin/Subscription.jsx

// import React from "react";
// import UpdateCard from "../../stripe/UpdateCard";
// import Invoices from "../../stripe/Invoices";
// import CancelSubscription from "../../stripe/CancelSubscription";
// import SubscriptionDetails from "../../stripe/SubscriptionDetails";
// import { useSelector } from "react-redux";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import CheckoutPage from "../../stripe/CheckoutForm";

// const stripePromise = loadStripe("pk_test_51QKfxKBV7cs4OT98DTH5t3cZ7xsZWnQepUU6izFRZ3ycDwLbw5SbsQXpIKorDzNfxE22SX0mm0UmZ9p3iOGUD9Sn00TdyeGp4D");

// const Subscription = () => {
//   // Get user subscription data
//   const { user } = useSelector(
//     (state) => state.auth
//   );
//   const hasActiveSubscription = Boolean(user && user?.subscriptionId); // Checks if user has an active subscription

//   // Display loader if user data is still loading
//   // if (!user) {
//   //   return console.log("Loading subscription details...");
//   // }

//   return (
//     console.log("Subscription_user::",user),
//     <div className="p-1">
//       <h1 className="text-2xl font-semibold mb-4">Subscription</h1>
//       <p className="mb-6">Manage your subscription plans and billing here.</p>

//       {hasActiveSubscription ? (
//         <>
//           {/* Show subscription components if the user has an active subscription */}
//           <SubscriptionDetails />
//           <UpdateCard />
//           <Invoices />
//           <CancelSubscription />
//         </>
//       ) : (
//         // Show CheckoutForm if the user doesn't have an active subscription
       
//         <div className="col-span-6 bg-white border-l px-5">
//         <Elements stripe={stripePromise}>
//         <CheckoutPage planId={"prod_REgn9n0Zyi1zRp"} />
//       </Elements>
//       </div>
//       )}
//     </div>
//   );
// };

// export default Subscription;