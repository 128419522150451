import {
    ChevronDownIcon,
    ChevronUpIcon,
    PencilSquareIcon,
    LinkIcon,
    TrashIcon,
  } from "@heroicons/react/24/solid";
  
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import { IoSaveOutline } from "react-icons/io5"; // Save Icon from react-icons
  
  // Helper function to conditionally join class names
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  
  const CommonTable = ({
    data,
    currentPage,
    itemsPerPage,
    setCurrentPage,
    searchTerm,
    handleSort,
    sortDirection,
    filterRange,
    customStartDate,
    customEndDate,
    setCustomStartDate,
    setCustomEndDate,
    setItemsPerPage,
    setSearchTerm,
    setFilterRange,
    handleEdit,
    handleDelete,
    handleSave,
    handleCopyLink,
    editingItemId,
    newName,
    setNewName,
    updateInfographicById, // API function for updating the infographic
  }) => {
    // Function to handle filtering and sorting
    const getFilteredItems = () => {
      let filtered = [...data];
  
      // Search Filter
      if (searchTerm) {
        filtered = filtered.filter(
          (item) =>
            (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) || // Ensure name exists
            (item.id && item.id.toString().includes(searchTerm)) // Ensure id exists
        );
      }
  
      // Date Range Filter
      const today = new Date();
      if (filterRange === "today") {
        filtered = filtered.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return (
            itemDate.getDate() === today.getDate() &&
            itemDate.getMonth() === today.getMonth() &&
            itemDate.getFullYear() === today.getFullYear()
          );
        });
      } else if (filterRange === "last7") {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(today.getDate() - 7);
        filtered = filtered.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= sevenDaysAgo && itemDate <= today;
        });
      } else if (filterRange === "custom" && customStartDate && customEndDate) {
        filtered = filtered.filter((item) => {
          const itemDate = new Date(item.createdAt);
          return itemDate >= customStartDate && itemDate <= customEndDate;
        });
      }
  
      return filtered;
    };
  
    const sortedItems = getFilteredItems().sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (sortDirection === "asc") {
        return dateA - dateB;
      }
      return dateB - dateA;
    });
  
    const totalPages = Math.ceil(sortedItems.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedItems = sortedItems.slice(startIndex, startIndex + itemsPerPage);
  
    // Handle Page Change
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const handleSaveClick = async (id) => {
      if (newName.trim() === "") {
        alert("Name cannot be empty.");
        return;
      }
  
      const dataToSend = {
        id: id,
        name: newName, // Updated name
      };
  
      try {
        // Ensure you're calling the function that was passed as a prop
        const response = await handleSave(id);  // Call the handleSave function passed down as prop
        if (response) {
          alert("Name updated successfully!");
          handleEdit(null); // Exit edit mode
          setNewName(""); // Clear the input
        }
      } catch (error) {
        console.error("Failed to update the name", error);
        alert("Failed to update the name.");
      }
    };
  
    // Handle Edit Button
    const handleEditClick = (id, currentName) => {
      handleEdit(id); // Set the item as being edited
      setNewName(currentName); // Set the current name as the new name in the input
    };
  
    return (
      <div className="overflow-x-auto rounded-md border bg-white shadow-md">
        {/* Filters Section */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-4 space-y-4 md:space-y-0 p-6 bg-gray-50 rounded-md">
          {/* Search Bar */}
          <div className="flex items-center space-x-2 w-full md:w-1/4">
            <input
              type="text"
              placeholder="Search by Name or ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
  
          {/* Items Per Page */}
          <div className="flex items-center space-x-3 w-full md:w-1/3">
            <label htmlFor="itemsPerPage" className="text-gray-700 font-medium">Items per page:</label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset to first page
              }}
              className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
  
          {/* Sort By Date */}
          <div className="flex items-center space-x-2 w-full md:w-1/3 justify-end">
            <button
              onClick={handleSort}
              className="flex items-center border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Sort by Date"
            >
              Sort by Date
              {sortDirection === "asc" ? (
                <ChevronUpIcon className="h-5 w-5 ml-2" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 ml-2" />
              )}
            </button>
          </div>
        </div>
  
        {/* Date Range Filters */}
        <div className="flex flex-wrap items-center justify-between mb-6 px-6">
          <div className="flex items-center space-x-4">
            <label className="text-gray-700 font-medium">Date Range:</label>
            <select
              value={filterRange}
              onChange={(e) => {
                setFilterRange(e.target.value);
                setCustomStartDate(null);
                setCustomEndDate(null);
                setCurrentPage(1);
              }}
              className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="all">All</option>
              <option value="today">Today</option>
              <option value="last7">Last 7 Days</option>
              <option value="custom">Custom Range</option>
            </select>
            {filterRange === "custom" && (
              <div className="flex space-x-2">
                <DatePicker
                  selected={customStartDate}
                  onChange={(date) => setCustomStartDate(date)}
                  selectsStart
                  startDate={customStartDate}
                  endDate={customEndDate}
                  placeholderText="Start Date"
                  className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  maxDate={new Date()}
                />
                <DatePicker
                  selected={customEndDate}
                  onChange={(date) => setCustomEndDate(date)}
                  selectsEnd
                  startDate={customStartDate}
                  endDate={customEndDate}
                  minDate={customStartDate}
                  placeholderText="End Date"
                  className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  maxDate={new Date()}
                />
              </div>
            )}
          </div>
        </div>
  
        {/* Table */}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-blue-100 text-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedItems.length > 0 ? (
              paginatedItems.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {editingItemId === item.id ? (
                      <input
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        className="w-full px-2 py-1 border border-blue-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    ) : (
                      item.name // Display name
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.type}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <div className="flex justify-end gap-4">
                      {editingItemId === item.id ? (
                        <>
                          <button
                            onClick={() => handleSaveClick(item.id)}
                            className="border border-green-500 text-green-500 px-2 py-1 rounded hover:bg-green-500 hover:text-white flex items-center gap-1 focus:outline-none"
                          >
                            <IoSaveOutline className="h-4 w-4" />
                            <span className="sr-only">Save</span>
                          </button>
                          <button
                            onClick={() => handleEdit(null)} // Reset editing mode
                            className="border border-gray-500 text-gray-500 px-2 py-1 rounded hover:bg-gray-500 hover:text-white flex items-center gap-1 focus:outline-none"
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => handleEditClick(item.id, item.name)}
                            className="border border-yellow-500 text-yellow-500 px-2 py-1 rounded hover:bg-yellow-500 hover:text-white flex items-center gap-1 focus:outline-none"
                            disabled={true}
                          >
                            <PencilSquareIcon className="h-4 w-4" />
                            <span className="sr-only">Edit</span>
                          </button>
                          <button
                            onClick={() => handleDelete(item.id)}
                            className="border border-red-500 text-red-500 px-2 py-1 rounded hover:bg-red-500 hover:text-white flex items-center gap-1 focus:outline-none"
                          >
                            <TrashIcon className="h-4 w-4" />
                            <span className="sr-only">Delete</span>
                          </button>
                          <button
                            onClick={() => handleCopyLink(item.id)}
                            className="border border-blue-500 text-blue-500 px-2 py-1 rounded hover:bg-blue-500 hover:text-white flex items-center gap-1 focus:outline-none"
                          >
                            <LinkIcon className="h-4 w-4" />
                            <span className="sr-only">Copy URL</span>
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">No records found.</td>
              </tr>
            )}
          </tbody>
        </table>
  
        {/* Pagination */}
        <div className="flex flex-col md:flex-row justify-between items-center mt-4 px-6">
          <div className="flex items-center space-x-2 mb-4 md:mb-0">
            <span className="text-gray-700">
              Showing {startIndex + 1} to {startIndex + paginatedItems.length} of {sortedItems.length} entries
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-3 py-1 rounded-md border ${currentPage === 1 ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
              aria-label="Previous Page"
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={`px-3 py-1 rounded-md border ${currentPage === i + 1 ? "bg-blue-500 text-white border-blue-500" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
                aria-label={`Go to page ${i + 1}`}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-3 py-1 rounded-md border ${currentPage === totalPages ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
              aria-label="Next Page"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default CommonTable;
// import {
//     ChevronDownIcon,
//     ChevronUpIcon,
//     PencilSquareIcon,
//     LinkIcon,
//     TrashIcon,
//   } from "@heroicons/react/24/solid";
  
//   import DatePicker from "react-datepicker";
//   import "react-datepicker/dist/react-datepicker.css";
//   import { IoSaveOutline } from "react-icons/io5"; // Save Icon from react-icons
  
//   // Helper function to conditionally join class names
//   function classNames(...classes) {
//     return classes.filter(Boolean).join(" ");
//   }
  
//   const CommonTable = ({
//     data,
//     currentPage,
//     itemsPerPage,
//     setCurrentPage,
//     searchTerm,
//     handleSort,
//     sortDirection,
//     filterRange,
//     customStartDate,
//     customEndDate,
//     setCustomStartDate,
//     setCustomEndDate,
//     setItemsPerPage,
//     setSearchTerm,
//     setFilterRange,
//     handleEdit,
//     handleDelete,
//     handleSave,
//     handleCopyLink,
//     editingItemId,
//     newName,
//     setNewName,
//     updateInfographicById, // API function for updating the infographic
//   }) => {
//     // Function to handle filtering and sorting
//     const getFilteredItems = () => {
//       let filtered = [...data];
  
//       // Search Filter
//       if (searchTerm) {
//         filtered = filtered.filter(
//           (item) =>
//             (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) || // Ensure name exists
//             (item.id && item.id.toString().includes(searchTerm)) // Ensure id exists
//         );
//       }
  
//       // Date Range Filter
//       const today = new Date();
//       if (filterRange === "today") {
//         filtered = filtered.filter((item) => {
//           const itemDate = new Date(item.createdAt);
//           return (
//             itemDate.getDate() === today.getDate() &&
//             itemDate.getMonth() === today.getMonth() &&
//             itemDate.getFullYear() === today.getFullYear()
//           );
//         });
//       } else if (filterRange === "last7") {
//         const sevenDaysAgo = new Date();
//         sevenDaysAgo.setDate(today.getDate() - 7);
//         filtered = filtered.filter((item) => {
//           const itemDate = new Date(item.createdAt);
//           return itemDate >= sevenDaysAgo && itemDate <= today;
//         });
//       } else if (filterRange === "custom" && customStartDate && customEndDate) {
//         filtered = filtered.filter((item) => {
//           const itemDate = new Date(item.createdAt);
//           return itemDate >= customStartDate && itemDate <= customEndDate;
//         });
//       }
  
//       return filtered;
//     };
  
//     const sortedItems = getFilteredItems().sort((a, b) => {
//       const dateA = new Date(a.createdAt);
//       const dateB = new Date(b.createdAt);
//       if (sortDirection === "asc") {
//         return dateA - dateB;
//       }
//       return dateB - dateA;
//     });
  
//     const totalPages = Math.ceil(sortedItems.length / itemsPerPage);
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const paginatedItems = sortedItems.slice(startIndex, startIndex + itemsPerPage);
  
//     // Handle Page Change
//     const handlePageChange = (pageNumber) => {
//       setCurrentPage(pageNumber);
//     };
  
//     // Handle Save Button
//     const handleSaveClick = async (id) => {
//       if (newName.trim() === "") {
//         alert("Name cannot be empty.");
//         return;
//       }
  
//       const dataToSend = {
//         id: id,
//         name: newName, // Updated name
//       };
  
//       try {
//         // Call the update API
//         const response = await updateInfographicById(id, dataToSend);
//         if (response) {
//           alert("Name updated successfully!");
//           handleEdit(null); // Exit edit mode
//           setNewName(""); // Clear the new name input
//         }
//       } catch (error) {
//         console.error("Failed to update the name", error);
//         alert("Failed to update the name.");
//       }
//     };
  
//     // Handle Edit Button
//     const handleEditClick = (id, currentName) => {
//       handleEdit(id); // Set the item as being edited
//       setNewName(currentName); // Set the current name as the new name in the input
//     };
  
//     return (
//       <div className="overflow-x-auto rounded-md border">
//         {/* Filters Section */}
//         <div className="flex flex-col md:flex-row justify-between mb-4 space-y-4 md:space-y-0 p-4">
//           {/* Search Bar */}
//           <div className="flex items-center">
//             <input
//               type="text"
//               placeholder="Search by Name or ID"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//             />
//           </div>
  
//           {/* Items Per Page */}
//           <div className="flex items-center">
//             <label htmlFor="itemsPerPage" className="mr-2 text-gray-700">
//               Items per page:
//             </label>
//             <select
//               id="itemsPerPage"
//               value={itemsPerPage}
//               onChange={(e) => {
//                 setItemsPerPage(Number(e.target.value));
//                 setCurrentPage(1); // Reset to first page
//               }}
//               className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//             >
//               <option value={10}>10</option>
//               <option value={25}>25</option>
//               <option value={50}>50</option>
//               <option value={100}>100</option>
//             </select>
//           </div>
  
//           {/* Sort By Date */}
//           <div className="flex items-center">
//             <button
//               onClick={handleSort}
//               className="flex items-center border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//               aria-label="Sort by Date"
//             >
//               Sort by Date
//               {sortDirection === "asc" ? (
//                 <ChevronUpIcon className="h-4 w-4 ml-2" />
//               ) : (
//                 <ChevronDownIcon className="h-4 w-4 ml-2" />
//               )}
//             </button>
//           </div>
//         </div>
  
//         {/* Date Range Filters */}
//         <div className="flex items-center space-x-4 mb-4 px-4">
//           <label className="text-gray-700">Date Range:</label>
//           <select
//             value={filterRange}
//             onChange={(e) => {
//               setFilterRange(e.target.value);
//               setCustomStartDate(null);
//               setCustomEndDate(null);
//               setCurrentPage(1);
//             }}
//             className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//           >
//             <option value="all">All</option>
//             <option value="today">Today</option>
//             <option value="last7">Last 7 Days</option>
//             <option value="custom">Custom Range</option>
//           </select>
//           {filterRange === "custom" && (
//             <>
//               <DatePicker
//                 selected={customStartDate}
//                 onChange={(date) => setCustomStartDate(date)}
//                 selectsStart
//                 startDate={customStartDate}
//                 endDate={customEndDate}
//                 placeholderText="Start Date"
//                 className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 maxDate={new Date()}
//               />
//               <DatePicker
//                 selected={customEndDate}
//                 onChange={(date) => setCustomEndDate(date)}
//                 selectsEnd
//                 startDate={customStartDate}
//                 endDate={customEndDate}
//                 minDate={customStartDate}
//                 placeholderText="End Date"
//                 className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 maxDate={new Date()}
//               />
//             </>
//           )}
//         </div>
  
//         {/* Table */}
//         <table className="min-w-full divide-y divide-gray-200">
//           <thead className="bg-gray-50">
//             <tr>
//               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name / ID</th>
//               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
//               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
//               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th> {/* New Actions column */}
//             </tr>
//           </thead>
//           <tbody className="bg-white divide-y divide-gray-200">
//             {paginatedItems.length > 0 ? (
//               paginatedItems.map((item) => (
//                 <tr key={item.id}>
//                   <td className="px-6 py-4 whitespace-nowrap">
//                     {editingItemId === item.id ? (
//                       <input
//                         type="text"
//                         value={newName}
//                         onChange={(e) => setNewName(e.target.value)}
//                         className="w-full px-2 py-1 border border-blue-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//                       />
//                     ) : (
//                       `${item.name} ${item.id}` // Display name and ID
//                     )}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
//                   <td className="px-6 py-4 whitespace-nowrap">{item.type}</td>
//                   <td className="px-6 py-4 whitespace-nowrap text-right">
//                     {/* Action buttons */}
//                     <div className="flex justify-end gap-2">
//                       {editingItemId === item.id ? (
//                         <>
//                           <button
//                             onClick={() => handleSaveClick(item.id)}
//                             className="border border-green-500 text-green-500 px-2 py-1 rounded hover:bg-green-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             <IoSaveOutline className="h-4 w-4" />
//                             <span className="sr-only">Save</span>
//                           </button>
//                           <button
//                             onClick={() => handleEdit(null)} // Reset editing mode
//                             className="border border-gray-500 text-gray-500 px-2 py-1 rounded hover:bg-gray-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             Cancel
//                           </button>
//                         </>
//                       ) : (
//                         <>
//                           <button
//                             onClick={() => handleEditClick(item.id, item.name)}
//                             className="border border-yellow-500 text-yellow-500 px-2 py-1 rounded hover:bg-yellow-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             <PencilSquareIcon className="h-4 w-4" />
//                             <span className="sr-only">Edit</span>
//                           </button>
//                           <button
//                             onClick={() => handleDelete(item.id)}
//                             className="border border-red-500 text-red-500 px-2 py-1 rounded hover:bg-red-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             <TrashIcon className="h-4 w-4" />
//                             <span className="sr-only">Delete</span>
//                           </button>
//                           <button
//                             onClick={() => handleCopyLink(item.id)}
//                             className="border border-blue-500 text-blue-500 px-2 py-1 rounded hover:bg-blue-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             <LinkIcon className="h-4 w-4" />
//                             <span className="sr-only">Copy URL</span>
//                           </button>
//                         </>
//                       )}
//                     </div>
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="4" className="px-6 py-4 text-center text-gray-500">No records found.</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
  
//         {/* Pagination */}
//         <div className="flex flex-col md:flex-row justify-between items-center mt-4">
//           <div className="flex items-center space-x-2 mb-4 md:mb-0">
//             <span className="text-gray-700">
//               Showing {startIndex + 1} to {startIndex + paginatedItems.length} of {sortedItems.length} entries
//             </span>
//           </div>
//           <div className="flex items-center space-x-2">
//             <button
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//               className={`px-3 py-1 rounded-md border ${currentPage === 1 ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//               aria-label="Previous Page"
//             >
//               Previous
//             </button>
//             {Array.from({ length: totalPages }, (_, i) => (
//               <button
//                 key={i + 1}
//                 onClick={() => handlePageChange(i + 1)}
//                 className={`px-3 py-1 rounded-md border ${currentPage === i + 1 ? "bg-blue-500 text-white border-blue-500" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//                 aria-label={`Go to page ${i + 1}`}
//               >
//                 {i + 1}
//               </button>
//             ))}
//             <button
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//               className={`px-3 py-1 rounded-md border ${currentPage === totalPages ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//               aria-label="Next Page"
//             >
//               Next
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   };
  
//   export default CommonTable;
// import {
//     ChevronDownIcon,
//     ChevronUpIcon,
//     PencilSquareIcon,
//     LinkIcon,
//     TrashIcon,
//   } from "@heroicons/react/24/solid";
  
//   import DatePicker from "react-datepicker";
//   import "react-datepicker/dist/react-datepicker.css";
//   import { IoSaveOutline } from "react-icons/io5"; // Save Icon from react-icons
  
//   // Helper function to conditionally join class names
//   function classNames(...classes) {
//     return classes.filter(Boolean).join(" ");
//   }
  
//   const CommonTable = ({
//     data,
//     currentPage,
//     itemsPerPage,
//     setCurrentPage,
//     searchTerm,
//     handleSort,
//     sortDirection,
//     filterRange,
//     customStartDate,
//     customEndDate,
//     setCustomStartDate,
//     setCustomEndDate,
//     setItemsPerPage,
//     setSearchTerm,
//     setFilterRange,
//     handleEdit,
//     handleDelete,
//     handleSave,
//     handleCopyLink,
//     editingItemId,
//     newName,
//     setNewName,
//     updateInfographicById, // API function for updating the infographic
//   }) => {
//     // Function to handle filtering and sorting
//     const getFilteredItems = () => {
//       let filtered = [...data];
  
//       // Search Filter
//       if (searchTerm) {
//         filtered = filtered.filter(
//           (item) =>
//             (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) || // Ensure name exists
//             (item.id && item.id.toString().includes(searchTerm)) // Ensure id exists
//         );
//       }
  
//       // Date Range Filter
//       const today = new Date();
//       if (filterRange === "today") {
//         filtered = filtered.filter((item) => {
//           const itemDate = new Date(item.createdAt);
//           return (
//             itemDate.getDate() === today.getDate() &&
//             itemDate.getMonth() === today.getMonth() &&
//             itemDate.getFullYear() === today.getFullYear()
//           );
//         });
//       } else if (filterRange === "last7") {
//         const sevenDaysAgo = new Date();
//         sevenDaysAgo.setDate(today.getDate() - 7);
//         filtered = filtered.filter((item) => {
//           const itemDate = new Date(item.createdAt);
//           return itemDate >= sevenDaysAgo && itemDate <= today;
//         });
//       } else if (filterRange === "custom" && customStartDate && customEndDate) {
//         filtered = filtered.filter((item) => {
//           const itemDate = new Date(item.createdAt);
//           return itemDate >= customStartDate && itemDate <= customEndDate;
//         });
//       }
  
//       return filtered;
//     };
  
//     const sortedItems = getFilteredItems().sort((a, b) => {
//       const dateA = new Date(a.createdAt);
//       const dateB = new Date(b.createdAt);
//       if (sortDirection === "asc") {
//         return dateA - dateB;
//       }
//       return dateB - dateA;
//     });
  
//     const totalPages = Math.ceil(sortedItems.length / itemsPerPage);
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const paginatedItems = sortedItems.slice(startIndex, startIndex + itemsPerPage);
  
//     // Handle Page Change
//     const handlePageChange = (pageNumber) => {
//       setCurrentPage(pageNumber);
//     };
  
//     // Handle Save Button
//     const handleSaveClick = async (id) => {
//       if (newName.trim() === "") {
//         alert("Name cannot be empty.");
//         return;
//       }
  
//       const dataToSend = {
//         id: id,
//         name: newName, // Updated name
//       };
  
//       try {
//         // Call the update API
//         const response = await updateInfographicById(id, dataToSend);
//         if (response) {
//           alert("Name updated successfully!");
//           handleEdit(null); // Exit edit mode
//           setNewName(""); // Clear the new name input
//         }
//       } catch (error) {
//         console.error("Failed to update the name", error);
//         alert("Failed to update the name.");
//       }
//     };
  
//     // Handle Edit Button
//     const handleEditClick = (id, currentName) => {
//       handleEdit(id); // Set the item as being edited
//       setNewName(currentName); // Set the current name as the new name in the input
//     };
  
//     return (
//       <div className="overflow-x-auto rounded-md border">
//         {/* Filters Section */}
//         <div className="flex flex-col md:flex-row justify-between mb-4 space-y-4 md:space-y-0">
//           {/* Search Bar */}
//           <div className="flex items-center">
//             <input
//               type="text"
//               placeholder="Search by Name or ID"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//             />
//           </div>
  
//           {/* Items Per Page */}
//           <div className="flex items-center">
//             <label htmlFor="itemsPerPage" className="mr-2 text-gray-700">
//               Items per page:
//             </label>
//             <select
//               id="itemsPerPage"
//               value={itemsPerPage}
//               onChange={(e) => {
//                 setItemsPerPage(Number(e.target.value));
//                 setCurrentPage(1); // Reset to first page
//               }}
//               className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//             >
//               <option value={10}>10</option>
//               <option value={25}>25</option>
//               <option value={50}>50</option>
//               <option value={100}>100</option>
//             </select>
//           </div>
  
//           {/* Sort By Date */}
//           <div className="flex items-center">
//             <button
//               onClick={handleSort}
//               className="flex items-center border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//               aria-label="Sort by Date"
//             >
//               Sort by Date
//               {sortDirection === "asc" ? (
//                 <ChevronUpIcon className="h-4 w-4 ml-2" />
//               ) : (
//                 <ChevronDownIcon className="h-4 w-4 ml-2" />
//               )}
//             </button>
//           </div>
//         </div>
  
//         {/* Date Range Filters */}
//         <div className="flex items-center space-x-2 mb-4">
//           <label className="text-gray-700">Date Range:</label>
//           <select
//             value={filterRange}
//             onChange={(e) => {
//               setFilterRange(e.target.value);
//               setCustomStartDate(null);
//               setCustomEndDate(null);
//               setCurrentPage(1);
//             }}
//             className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//           >
//             <option value="all">All</option>
//             <option value="today">Today</option>
//             <option value="last7">Last 7 Days</option>
//             <option value="custom">Custom Range</option>
//           </select>
//           {filterRange === "custom" && (
//             <>
//               <DatePicker
//                 selected={customStartDate}
//                 onChange={(date) => setCustomStartDate(date)}
//                 selectsStart
//                 startDate={customStartDate}
//                 endDate={customEndDate}
//                 placeholderText="Start Date"
//                 className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 maxDate={new Date()}
//               />
//               <DatePicker
//                 selected={customEndDate}
//                 onChange={(date) => setCustomEndDate(date)}
//                 selectsEnd
//                 startDate={customStartDate}
//                 endDate={customEndDate}
//                 minDate={customStartDate}
//                 placeholderText="End Date"
//                 className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 maxDate={new Date()}
//               />
//             </>
//           )}
//         </div>
  
//         {/* Table */}
//         <table className="min-w-full divide-y divide-gray-200">
//           <thead className="bg-gray-50">
//             <tr>
//               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name / ID</th>
//               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
//               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
//               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th> {/* New Actions column */}
//             </tr>
//           </thead>
//           <tbody className="bg-white divide-y divide-gray-200">
//             {paginatedItems.length > 0 ? (
//               paginatedItems.map((item) => (
//                 <tr key={item.id}>
//                   <td className="px-6 py-4 whitespace-nowrap">
//                     {editingItemId === item.id ? (
//                       <input
//                         type="text"
//                         value={newName}
//                         onChange={(e) => setNewName(e.target.value)}
//                         className="w-full px-2 py-1 border border-blue-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//                       />
//                     ) : (
//                       `${item.name} ${item.id}` // Display name and ID
//                     )}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
//                   <td className="px-6 py-4 whitespace-nowrap">{item.type}</td>
//                   <td className="px-6 py-4 whitespace-nowrap text-right">
//                     {/* Action buttons */}
//                     <div className="flex justify-end gap-2">
//                       {editingItemId === item.id ? (
//                         <>
//                           <button
//                             onClick={() => handleSaveClick(item.id)}
//                             className="border border-green-500 text-green-500 px-2 py-1 rounded hover:bg-green-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             <IoSaveOutline className="h-4 w-4" />
//                             <span className="sr-only">Save</span>
//                           </button>
//                           <button
//                             onClick={() => handleEdit(null)} // Reset editing mode
//                             className="border border-gray-500 text-gray-500 px-2 py-1 rounded hover:bg-gray-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             Cancel
//                           </button>
//                         </>
//                       ) : (
//                         <>
//                           <button
//                             onClick={() => handleEditClick(item.id, item.name)}
//                             className="border border-yellow-500 text-yellow-500 px-2 py-1 rounded hover:bg-yellow-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             <PencilSquareIcon className="h-4 w-4" />
//                             <span className="sr-only">Edit</span>
//                           </button>
//                           <button
//                             onClick={() => handleDelete(item.id)}
//                             className="border border-red-500 text-red-500 px-2 py-1 rounded hover:bg-red-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             <TrashIcon className="h-4 w-4" />
//                             <span className="sr-only">Delete</span>
//                           </button>
//                           <button
//                             onClick={() => handleCopyLink(item.id)}
//                             className="border border-blue-500 text-blue-500 px-2 py-1 rounded hover:bg-blue-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                           >
//                             <LinkIcon className="h-4 w-4" />
//                             <span className="sr-only">Copy URL</span>
//                           </button>
//                         </>
//                       )}
//                     </div>
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="4" className="px-6 py-4 text-center text-gray-500">No records found.</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
  
//         {/* Pagination */}
//         <div className="flex flex-col md:flex-row justify-between items-center mt-4">
//           <div className="flex items-center space-x-2 mb-4 md:mb-0">
//             <span className="text-gray-700">
//               Showing {startIndex + 1} to {startIndex + paginatedItems.length} of {sortedItems.length} entries
//             </span>
//           </div>
//           <div className="flex items-center space-x-2">
//             <button
//               onClick={() => handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//               className={`px-3 py-1 rounded-md border ${currentPage === 1 ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//               aria-label="Previous Page"
//             >
//               Previous
//             </button>
//             {Array.from({ length: totalPages }, (_, i) => (
//               <button
//                 key={i + 1}
//                 onClick={() => handlePageChange(i + 1)}
//                 className={`px-3 py-1 rounded-md border ${currentPage === i + 1 ? "bg-blue-500 text-white border-blue-500" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//                 aria-label={`Go to page ${i + 1}`}
//               >
//                 {i + 1}
//               </button>
//             ))}
//             <button
//               onClick={() => handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//               className={`px-3 py-1 rounded-md border ${currentPage === totalPages ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//               aria-label="Next Page"
//             >
//               Next
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   };
  
//   export default CommonTable;
// import {
//   ChevronDownIcon,
//   ChevronUpIcon,
//   PencilSquareIcon,
//   LinkIcon,
//   TrashIcon,
// } from "@heroicons/react/24/solid";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { IoSaveOutline } from "react-icons/io5";
// // Helper function to conditionally join class names
// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// const CommonTable = ({
//   data,
//   currentPage,
//   itemsPerPage,
//   setCurrentPage,
//   searchTerm,
//   handleSort,
//   sortDirection,
//   filterRange,
//   customStartDate,
//   customEndDate,
//   setCustomStartDate,
//   setCustomEndDate,
//   setItemsPerPage,
//   setSearchTerm,
//   setFilterRange,
//   handleEdit,
//   handleDelete,
//   handleSave,
//   handleCopyLink,
//   editingItemId,
//   newName,
//   setNewName,
// }) => {
//   // Function to handle filtering and sorting
//   const getFilteredItems = () => {
//     let filtered = [...data];

//     // Search Filter
//     if (searchTerm) {
//       filtered = filtered.filter(
//         (item) =>
//           (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) || // Ensure name exists
//           (item.id && item.id.toString().includes(searchTerm)) // Ensure id exists
//       );
//     }

//     // Date Range Filter
//     const today = new Date();
//     if (filterRange === "today") {
//       filtered = filtered.filter((item) => {
//         const itemDate = new Date(item.createdAt);
//         return (
//           itemDate.getDate() === today.getDate() &&
//           itemDate.getMonth() === today.getMonth() &&
//           itemDate.getFullYear() === today.getFullYear()
//         );
//       });
//     } else if (filterRange === "last7") {
//       const sevenDaysAgo = new Date();
//       sevenDaysAgo.setDate(today.getDate() - 7);
//       filtered = filtered.filter((item) => {
//         const itemDate = new Date(item.createdAt);
//         return itemDate >= sevenDaysAgo && itemDate <= today;
//       });
//     } else if (filterRange === "custom" && customStartDate && customEndDate) {
//       filtered = filtered.filter((item) => {
//         const itemDate = new Date(item.createdAt);
//         return itemDate >= customStartDate && itemDate <= customEndDate;
//       });
//     }

//     return filtered;
//   };

//   const sortedItems = getFilteredItems().sort((a, b) => {
//     const dateA = new Date(a.createdAt);
//     const dateB = new Date(b.createdAt);
//     if (sortDirection === "asc") {
//       return dateA - dateB;
//     }
//     return dateB - dateA;
//   });

//   const totalPages = Math.ceil(sortedItems.length / itemsPerPage);
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const paginatedItems = sortedItems.slice(startIndex, startIndex + itemsPerPage);

//   // Handle Page Change
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   return (
//     <div className="overflow-x-auto rounded-md border">
//       {/* Filters Section */}
//       <div className="flex flex-col md:flex-row justify-between mb-4 space-y-4 md:space-y-0">
//         {/* Search Bar */}
//         <div className="flex items-center">
//           <input
//             type="text"
//             placeholder="Search by Name or ID"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//         </div>

//         {/* Items Per Page */}
//         <div className="flex items-center">
//           <label htmlFor="itemsPerPage" className="mr-2 text-gray-700">
//             Items per page:
//           </label>
//           <select
//             id="itemsPerPage"
//             value={itemsPerPage}
//             onChange={(e) => {
//               setItemsPerPage(Number(e.target.value));
//               setCurrentPage(1); // Reset to first page
//             }}
//             className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//           >
//             <option value={10}>10</option>
//             <option value={25}>25</option>
//             <option value={50}>50</option>
//             <option value={100}>100</option>
//           </select>
//         </div>

//         {/* Sort By Date */}
//         <div className="flex items-center">
//           <button
//             onClick={handleSort}
//             className="flex items-center border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//             aria-label="Sort by Date"
//           >
//             Sort by Date
//             {sortDirection === "asc" ? (
//               <ChevronUpIcon className="h-4 w-4 ml-2" />
//             ) : (
//               <ChevronDownIcon className="h-4 w-4 ml-2" />
//             )}
//           </button>
//         </div>
//       </div>

//       {/* Date Range Filters */}
//       <div className="flex items-center space-x-2 mb-4">
//         <label className="text-gray-700">Date Range:</label>
//         <select
//           value={filterRange}
//           onChange={(e) => {
//             setFilterRange(e.target.value);
//             setCustomStartDate(null);
//             setCustomEndDate(null);
//             setCurrentPage(1);
//           }}
//           className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//         >
//           <option value="all">All</option>
//           <option value="today">Today</option>
//           <option value="last7">Last 7 Days</option>
//           <option value="custom">Custom Range</option>
//         </select>
//         {filterRange === "custom" && (
//           <>
//             <DatePicker
//               selected={customStartDate}
//               onChange={(date) => setCustomStartDate(date)}
//               selectsStart
//               startDate={customStartDate}
//               endDate={customEndDate}
//               placeholderText="Start Date"
//               className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//               maxDate={new Date()}
//             />
//             <DatePicker
//               selected={customEndDate}
//               onChange={(date) => setCustomEndDate(date)}
//               selectsEnd
//               startDate={customStartDate}
//               endDate={customEndDate}
//               minDate={customStartDate}
//               placeholderText="End Date"
//               className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
//               maxDate={new Date()}
//             />
//           </>
//         )}
//       </div>

//       {/* Table */}
//       <table className="min-w-full divide-y divide-gray-200">
//         <thead className="bg-gray-50">
//           <tr>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name / ID</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th> {/* New Actions column */}
//           </tr>
//         </thead>
//         <tbody className="bg-white divide-y divide-gray-200">
//           {paginatedItems.length > 0 ? (
//             paginatedItems.map((item) => (
//               <tr key={item.id}>
//                 <td className="px-6 py-4 whitespace-nowrap">{item.name} {item.id}</td> {/* Removed () around ID */}
//                 <td className="px-6 py-4 whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
//                 <td className="px-6 py-4 whitespace-nowrap">{item.type}</td>
//                 <td className="px-6 py-4 whitespace-nowrap text-right">
//                   {/* Action buttons */}
//                   <div className="flex justify-end gap-2">
//                     {editingItemId === item.id ? (
//                       <>
//                         <button
//                           onClick={() => handleSave(item.id)}
//                           className="border border-green-500 text-green-500 px-2 py-1 rounded hover:bg-green-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                         >
//                           <IoSaveOutline className="h-4 w-4" />
//                           <span className="sr-only">Save</span>
//                         </button>
//                         <button
//                           onClick={() => handleEdit(null)} // Reset editing mode
//                           className="border border-gray-500 text-gray-500 px-2 py-1 rounded hover:bg-gray-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                         >
//                           Cancel
//                         </button>
//                       </>
//                     ) : (
//                       <>
//                         <button
//                           onClick={() => handleEdit(item.id, item.name)}
//                           className="border border-yellow-500 text-yellow-500 px-2 py-1 rounded hover:bg-yellow-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                         >
//                           <PencilSquareIcon className="h-4 w-4" />
//                           <span className="sr-only">Edit</span>
//                         </button>
//                         <button
//                           onClick={() => handleDelete(item.id)}
//                           className="border border-red-500 text-red-500 px-2 py-1 rounded hover:bg-red-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                         >
//                           <TrashIcon className="h-4 w-4" />
//                           <span className="sr-only">Delete</span>
//                         </button>
//                         <button
//                           onClick={() => handleCopyLink(item.id)}
//                           className="border border-blue-500 text-blue-500 px-2 py-1 rounded hover:bg-blue-500 hover:text-white flex items-center gap-1 focus:outline-none"
//                         >
//                           <LinkIcon className="h-4 w-4" />
//                           <span className="sr-only">Copy URL</span>
//                         </button>
//                       </>
//                     )}
//                   </div>
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="4" className="px-6 py-4 text-center text-gray-500">No records found.</td>
//             </tr>
//           )}
//         </tbody>
//       </table>

//       {/* Pagination */}
//       <div className="flex flex-col md:flex-row justify-between items-center mt-4">
//         <div className="flex items-center space-x-2 mb-4 md:mb-0">
//           <span className="text-gray-700">
//             Showing {startIndex + 1} to {startIndex + paginatedItems.length} of {sortedItems.length} entries
//           </span>
//         </div>
//         <div className="flex items-center space-x-2">
//           <button
//             onClick={() => handlePageChange(currentPage - 1)}
//             disabled={currentPage === 1}
//             className={`px-3 py-1 rounded-md border ${currentPage === 1 ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//             aria-label="Previous Page"
//           >
//             Previous
//           </button>
//           {Array.from({ length: totalPages }, (_, i) => (
//             <button
//               key={i + 1}
//               onClick={() => handlePageChange(i + 1)}
//               className={`px-3 py-1 rounded-md border ${currentPage === i + 1 ? "bg-blue-500 text-white border-blue-500" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//               aria-label={`Go to page ${i + 1}`}
//             >
//               {i + 1}
//             </button>
//           ))}
//           <button
//             onClick={() => handlePageChange(currentPage + 1)}
//             disabled={currentPage === totalPages}
//             className={`px-3 py-1 rounded-md border ${currentPage === totalPages ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
//             aria-label="Next Page"
//           >
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CommonTable;
// // import React from "react";
// // import DatePicker from "react-datepicker";
// // import "react-datepicker/dist/react-datepicker.css";
// // import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

// // // Helper function to conditionally join class names
// // function classNames(...classes) {
// //   return classes.filter(Boolean).join(" ");
// // }

// // const CommonTable = ({
// //   data,
// //   currentPage,
// //   itemsPerPage,
// //   setCurrentPage,
// //   searchTerm,
// //   handleSort,
// //   sortDirection,
// //   filterRange,
// //   customStartDate,
// //   customEndDate,
// //   setCustomStartDate,
// //   setCustomEndDate,
// //   setItemsPerPage,
// //   setSearchTerm,
// //   setFilterRange,
// // }) => {
// //   // Function to handle filtering and sorting
// //   const getFilteredItems = () => {
// //     let filtered = [...data];

// //     // Search Filter
// //     if (searchTerm) {
// //       filtered = filtered.filter(
// //         (item) =>
// //           (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) || // Ensure name exists
// //           (item.id && item.id.toString().includes(searchTerm)) // Ensure id exists
// //       );
// //     }

// //     // Date Range Filter
// //     const today = new Date();
// //     if (filterRange === "today") {
// //       filtered = filtered.filter((item) => {
// //         const itemDate = new Date(item.createdAt);
// //         return (
// //           itemDate.getDate() === today.getDate() &&
// //           itemDate.getMonth() === today.getMonth() &&
// //           itemDate.getFullYear() === today.getFullYear()
// //         );
// //       });
// //     } else if (filterRange === "last7") {
// //       const sevenDaysAgo = new Date();
// //       sevenDaysAgo.setDate(today.getDate() - 7);
// //       filtered = filtered.filter((item) => {
// //         const itemDate = new Date(item.createdAt);
// //         return itemDate >= sevenDaysAgo && itemDate <= today;
// //       });
// //     } else if (filterRange === "custom" && customStartDate && customEndDate) {
// //       filtered = filtered.filter((item) => {
// //         const itemDate = new Date(item.createdAt);
// //         return itemDate >= customStartDate && itemDate <= customEndDate;
// //       });
// //     }

// //     return filtered;
// //   };

// //   const sortedItems = getFilteredItems().sort((a, b) => {
// //     const dateA = new Date(a.createdAt);
// //     const dateB = new Date(b.createdAt);
// //     if (sortDirection === "asc") {
// //       return dateA - dateB;
// //     }
// //     return dateB - dateA;
// //   });

// //   const totalPages = Math.ceil(sortedItems.length / itemsPerPage);
// //   const startIndex = (currentPage - 1) * itemsPerPage;
// //   const paginatedItems = sortedItems.slice(startIndex, startIndex + itemsPerPage);

// //   // Handle Page Change
// //   const handlePageChange = (pageNumber) => {
// //     setCurrentPage(pageNumber);
// //   };

// //   return (
// //     <div className="overflow-x-auto rounded-md border">
// //       {/* Filters Section */}
// //       <div className="flex flex-col md:flex-row justify-between mb-4 space-y-4 md:space-y-0 p-4">
// //         {/* Search Bar */}
// //         <div className="flex items-center">
// //           <input
// //             type="text"
// //             placeholder="Search by Name or ID"
// //             value={searchTerm}
// //             onChange={(e) => setSearchTerm(e.target.value)}
// //             className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
// //           />
// //         </div>

// //         {/* Items Per Page */}
// //         <div className="flex items-center">
// //           <label htmlFor="itemsPerPage" className="mr-2 text-gray-700">
// //             Items per page:
// //           </label>
// //           <select
// //             id="itemsPerPage"
// //             value={itemsPerPage}
// //             onChange={(e) => {
// //               setItemsPerPage(Number(e.target.value));
// //               setCurrentPage(1); // Reset to first page
// //             }}
// //             className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
// //           >
// //             <option value={10}>10</option>
// //             <option value={25}>25</option>
// //             <option value={50}>50</option>
// //             <option value={100}>100</option>
// //           </select>
// //         </div>

// //         {/* Sort By Date */}
// //         <div className="flex items-center">
// //           <button
// //             onClick={handleSort}
// //             className="flex items-center border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
// //             aria-label="Sort by Date"
// //           >
// //             Sort by Date
// //             {sortDirection === "asc" ? (
// //               <ChevronUpIcon className="h-4 w-4 ml-2" />
// //             ) : (
// //               <ChevronDownIcon className="h-4 w-4 ml-2" />
// //             )}
// //           </button>
// //         </div>
// //       </div>

// //       {/* Date Range Filters */}
// //       <div className="flex items-center space-x-4 mb-4 px-4">
// //         <label className="text-gray-700">Date Range:</label>
// //         <select
// //           value={filterRange}
// //           onChange={(e) => {
// //             setFilterRange(e.target.value);
// //             setCustomStartDate(null);
// //             setCustomEndDate(null);
// //             setCurrentPage(1);
// //           }}
// //           className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
// //         >
// //           <option value="all">All</option>
// //           <option value="today">Today</option>
// //           <option value="last7">Last 7 Days</option>
// //           <option value="custom">Custom Range</option>
// //         </select>
// //         {filterRange === "custom" && (
// //           <>
// //             <DatePicker
// //               selected={customStartDate}
// //               onChange={(date) => setCustomStartDate(date)}
// //               selectsStart
// //               startDate={customStartDate}
// //               endDate={customEndDate}
// //               placeholderText="Start Date"
// //               className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
// //               maxDate={new Date()}
// //             />
// //             <DatePicker
// //               selected={customEndDate}
// //               onChange={(date) => setCustomEndDate(date)}
// //               selectsEnd
// //               startDate={customStartDate}
// //               endDate={customEndDate}
// //               minDate={customStartDate}
// //               placeholderText="End Date"
// //               className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
// //               maxDate={new Date()}
// //             />
// //           </>
// //         )}
// //       </div>

// //       {/* Table */}
// //       <table className="min-w-full divide-y divide-gray-200">
// //         <thead className="bg-gray-50">
// //           <tr>
// //             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name / ID</th>
// //             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
// //             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
// //           </tr>
// //         </thead>
// //         <tbody className="bg-white divide-y divide-gray-200">
// //           {paginatedItems.length > 0 ? (
// //             paginatedItems.map((item) => (
// //               <tr key={item.id}>
// //                 <td className="px-6 py-4 whitespace-nowrap">{item.name} {item.id}</td> {/* Removed () around ID */}
// //                 <td className="px-6 py-4 whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
// //                 <td className="px-6 py-4 whitespace-nowrap">{item.type}</td>
// //               </tr>
// //             ))
// //           ) : (
// //             <tr>
// //               <td colSpan="3" className="px-6 py-4 text-center text-gray-500">No records found.</td>
// //             </tr>
// //           )}
// //         </tbody>
// //       </table>

// //       {/* Pagination */}
// //       <div className="flex flex-col md:flex-row justify-between items-center mt-4">
// //         <div className="flex items-center space-x-2 mb-4 md:mb-0">
// //           <span className="text-gray-700">
// //             Showing {startIndex + 1} to {startIndex + paginatedItems.length} of {sortedItems.length} entries
// //           </span>
// //         </div>
// //         <div className="flex items-center space-x-2">
// //           <button
// //             onClick={() => handlePageChange(currentPage - 1)}
// //             disabled={currentPage === 1}
// //             className={`px-3 py-1 rounded-md border ${currentPage === 1 ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
// //             aria-label="Previous Page"
// //           >
// //             Previous
// //           </button>
// //           {Array.from({ length: totalPages }, (_, i) => (
// //             <button
// //               key={i + 1}
// //               onClick={() => handlePageChange(i + 1)}
// //               className={`px-3 py-1 rounded-md border ${currentPage === i + 1 ? "bg-blue-500 text-white border-blue-500" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
// //               aria-label={`Go to page ${i + 1}`}
// //             >
// //               {i + 1}
// //             </button>
// //           ))}
// //           <button
// //             onClick={() => handlePageChange(currentPage + 1)}
// //             disabled={currentPage === totalPages}
// //             className={`px-3 py-1 rounded-md border ${currentPage === totalPages ? "border-gray-300 text-gray-300 cursor-not-allowed" : "border-gray-300 text-gray-700 hover:bg-gray-100"}`}
// //             aria-label="Next Page"
// //           >
// //             Next
// //           </button>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default CommonTable;