// src/components/InputField.jsx
import React from "react";

const InputField = ({
  label,
  type = "text",
  id,
  name,
  placeholder,
  value,
  onChange,
  error,
  ariaDescribedBy,
  required = true,
  className = "",
  additionalElements, // For icons or other elements inside the input
}) => {
  return (
    <div
      className={`flex font-inter flex-col bg-[#f6faff] rounded-[12px] ${className}`}
    >
      <label htmlFor={id} className="sr-only">
        {label}
      </label>
      <div className="relative">
        <input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="w-full h-11 bg-[#f6faff] placeholder:text-[#95B2D1] py-2.5 px-3.5 rounded-[12px] focus:outline-none "
          required={required}
          aria-describedby={ariaDescribedBy}
        />
        {additionalElements && (
          <div className="absolute inset-y-0 right-3 flex items-center">
            {additionalElements}
          </div>
        )}
      </div>
      {error && (
        <p id={ariaDescribedBy} className="mt-1 text-sm text-red-500">
          {error}
        </p>
      )}
    </div>
  );
};

export default InputField;
