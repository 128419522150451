// src/services/authService.js
import api from "./api";

// Signup
export const signup = async (name, email, password) => {
  try {
    const response = await api.post("/auth/signup", { name, email, password });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Login
export const login = async (email, password) => {
  try {
    const response = await api.post("/auth/login", { email, password });
    // Store the token
    console.log("login response:", response)
     const { accessToken, user } = response.data.data;
     localStorage.setItem("accessToken", accessToken);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Logout
export const logout = async () => {
  try {
    await api.get("/auth/logout");
  } catch (error) {
    throw error.response.data;
  }
};

// Forgot Password
export const forgotPassword = async (email) => {
  try {
    const response = await api.post("/auth/forgotPassword", { email });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};


// Refresh Token
export const refreshToken = async () => {
  try {
    const response = await api.post("/auth/refresh-token");
    // Update the token
        console.log("refreshToken response:", response);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
// Update password function
export const updatePassword = async (oldPassword, newPassword) => {
  try {
    const response = await api.patch('/auth/updatePassword', { oldPassword, newPassword });
    return response.data; // Success response with message or user data
  } catch (error) {
    throw error.response.data; // Throw the error message from the backend
  }
};
// Google OAuth (This typically involves redirecting the user)
export const googleAuth = () => {
  window.location.href =  `${process.env.BACKEND_SERVER_URL}/api/auth/google`; // Replace with your backend URL
};
