// src/services/userService.js
import api from "./api";

// Get Logged-in User's Data
export const getMe = async () => {
  try {
    console.log("fetching me the API", api);
    const response = await api.get("/users/me");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getUsersConditionSettings = async (userId) => {
  try {
    console.log("Fetching user condition settings", userId);
    const response = await api.get(`/condition-settings/${userId}`);
    return response.data;
  } catch (error) {
    // Handle error response
    if (error.response && error.response.data) {
      throw error.response.data;
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};


// Update User Profile
export const updateMe = async (name, email) => {
  try {
    const response = await api.patch("/users/me", { name, email });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Update User Password
export const updatePassword = async (
  currentPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const response = await api.patch("/users/me/password", {
      currentPassword,
      newPassword,
      confirmPassword,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Deactivate User Account
export const deactivateMe = async () => {
  try {
    const response = await api.delete("/users/me");
    // Remove the token upon deactivation
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Update User Profile
export const updateDefualtSizeMeasuringUnit = async (sizeMeasuringUnit) => {
  try {
    const response = await api.patch("/users/user-info", { defualtSizemeasuringUnits:sizeMeasuringUnit });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};