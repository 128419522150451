import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getSubscription } from "../utils/apis/subscriptionService"; // Assuming this is your backend API call
import UpdateCard from "./UpdateCard";
import CancelSubscription from "./CancelSubscription";

const SubscriptionDetails = ({ userId }) => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        setLoading(true); // Start loading before API call
        const subscriptionInformation = await getSubscription(userId); // Await the API call
        console.log("Subscription data fetched:", subscriptionInformation);
        setSubscription(subscriptionInformation.subscription); // Set subscription data
      } catch (err) {
        console.error("Error fetching subscription:", err); // Log error
        setError("Failed to fetch subscription details.");
        toast.error("Failed to fetch subscription details.");
      } finally {
        setLoading(false); // Stop loading after the fetch is done
      }
    };

    if (userId) {
      fetchSubscription();
    }
  }, [userId]); // Only run when userId changes

  // Show loading text while fetching
  if (loading) {
    return <p>Loading subscription details...</p>;
  }

  // Show error message if fetching fails
  if (error) {
    return <p>{error}</p>;
  }

  // Show subscription details if available
  if (!subscription) {
    return <p>No active subscription found.</p>;
  }

  // Check if the trial has ended
  const isTrialEnded = new Date(subscription.trial_end) < new Date();
  const isSubscriptionActive = subscription.status === "active" || subscription.status === "trialing";

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-md shadow-md">
      <h2 className="text-xl font-semibold mb-4">Subscription Details</h2>
      <p>
        <strong>Plan:</strong> {subscription.plan.name} ({subscription.plan.currency} {subscription.plan.price})
      </p>
      <p>
        <strong>Status:</strong> {subscription.status === "trialing" ? "Trialing" : "Active"}
      </p>
      <p>
        <strong>Next Billing Date:</strong> {new Date(subscription.current_period_end).toLocaleDateString()}
      </p>
      <p>
        <strong>Trial Period End:</strong> {new Date(subscription.trial_end).toLocaleDateString()}
      </p>
      {/* <p>
        <strong>Payment Method:</strong> {subscription.default_payment_method.card.brand.toUpperCase()} ending in{" "}
        {subscription.default_payment_method.card.last4}
      </p> */}

      {/* Conditional Rendering based on subscription status */}
      <div className="mt-4">
        {isSubscriptionActive ? (
          <p className="text-green-600">Your subscription is active!</p>
        ) : (
          <p className="text-red-600">Your subscription has expired or not active.</p>
        )}
      </div>

      {/* If there are no invoices, show the placeholder */}
      <div className="mt-4">
        {subscription.invoices.length === 0 ? (
          <p>No invoices found.</p>
        ) : (
          <div>
            <h3 className="text-lg font-semibold">Invoices:</h3>
            {subscription.invoices.map((invoice) => (
              <div key={invoice.id}>
                <p>{invoice.amount_due} {invoice.currency}</p>
                <p>{new Date(invoice.created_at).toLocaleDateString()}</p>
              </div>
            ))}
          </div>
        )}
      </div>
  </div>
  );
};

export default SubscriptionDetails;