import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Tab } from "@headlessui/react";
import CommonTable from "./historyTable"; // Import the CommonTable component
import { getUserInfographics, updateInfographicById, deleteInfographicById } from "../../utils/apis/inforgraphic"; // API functions

// Helper function to conditionally join class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HistoryTimeline = () => {
  const [sizeInfographics, setSizeInfographics] = useState([]);
  const [conditionInfographics, setConditionInfographics] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortDirection, setSortDirection] = useState("desc"); // 'asc' or 'desc'
  const [filterRange, setFilterRange] = useState("all"); // 'today', 'last7', 'custom', 'all'
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [editingItemId, setEditingItemId] = useState(null);
  const [newName, setNewName] = useState("");

  // Fetch infographics data
  const fetchInfographics = async () => {
    try {
      let { data } = await getUserInfographics(); // Assuming it returns the array directly
      data = data.infographics;
      if (Array.isArray(data)) {
        const sizeData = data.filter((item) => item.type.toLowerCase().includes("size"));
        const conditionData = data.filter((item) => !item.type.toLowerCase().includes("size"));
        setSizeInfographics(sizeData);
        setConditionInfographics(conditionData);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchInfographics(); // Fetch data on initial load
  }, []);

  // Combine both data arrays (condition and size infographics) into one
  const combinedInfographics = [...conditionInfographics, ...sizeInfographics];

  // Function to handle sorting
  const handleSort = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Handle Save Button in the parent component
  const handleSave = async (id) => {
    if (newName.trim() === "") {
      alert("Name cannot be empty.");
      return;
    }

    const dataToSend = {
      id: id,
      name: newName, // Updated name
    };

    console.log("Data to send:", dataToSend);
    try {
      // Call the API to update the infographic
      const response = await updateInfographicById(id, dataToSend);
      if (response) {
        alert("Name updated successfully!");

        // Refetch the data to refresh the table
        await fetchInfographics();

        setEditingItemId(null); // Reset editing mode
        setNewName(""); // Clear the input
      }
    } catch (error) {
      console.error("Failed to update the name", error);
      alert("Failed to update the name.");
    }
  };

  // Handle Delete Button in the parent component
  const handleDelete = async (id) => {
    try {
      const response = await deleteInfographicById(id); // Call the delete API function
      console.log("handleDelete_response:", response);
      if (response) {
        toast.success("Infographic deleted successfully!"); // Show success toast
  
        // Remove the deleted infographic from the state (filtered out based on ID)
        setSizeInfographics((prevData) => prevData.filter((item) => item.id !== id));
        setConditionInfographics((prevData) => prevData.filter((item) => item.id !== id));
  
        // Optionally, you can refetch the data from the backend if you want to ensure it's synchronized
        // await fetchInfographics();
      }
    } catch (error) {
      console.error("Failed to delete infographic", error);
      toast.error("Failed to delete infographic."); // Show error toast
    }
  };

  // Handle Edit Button in the parent component
  const handleEdit = (id, currentName) => {
    setEditingItemId(id); // Set the item as being edited
    setNewName(currentName); // Set the current name as the new name in the input
  };

  return (
    <div className="container mx-auto py-10">
      <h1 className="text-3xl font-bold text-center mb-8">History</h1>

      {/* Combined Tab for both Condition Infographics and Size Charts */}
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 justify-center mb-8 w-full sm:w-1/2 mx-auto">
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full py-2.5 text-sm font-medium text-blue-700 rounded-lg",
                selected ? "bg-white shadow" : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            Infographics
          </Tab>
        </Tab.List>

        <Tab.Panels>
          {/* Merged Tab Panel for both Condition and Size Infographics */}
          <Tab.Panel className="rounded-xl bg-white p-3">
            <CommonTable
              data={combinedInfographics} // Use combined data
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setCurrentPage={setCurrentPage}
              searchTerm={searchTerm}
              handleSort={handleSort}
              sortDirection={sortDirection}
              filterRange={filterRange}
              customStartDate={customStartDate}
              customEndDate={customEndDate}
              setCustomStartDate={setCustomStartDate}
              setCustomEndDate={setCustomEndDate}
              setItemsPerPage={setItemsPerPage}
              setSearchTerm={setSearchTerm}
              setFilterRange={setFilterRange}
              editingItemId={editingItemId}
              newName={newName}
              setNewName={setNewName}
              handleSave={handleSave} // Passing handleSave to CommonTable
              handleEdit={handleEdit} // Passing handleEdit to CommonTable
              handleDelete={handleDelete} // Passing handleDelete to CommonTable
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default HistoryTimeline;
// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { Tab } from "@headlessui/react";
// import CommonTable from "./historyTable"; // Import the CommonTable component
// import { getUserInfographics, updateInfographicById, deleteInfographicById } from "../../utils/apis/inforgraphic"; // API functions

// // Helper function to conditionally join class names
// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// const HistoryTimeline = () => {
//   const [sizeInfographics, setSizeInfographics] = useState([]);
//   const [conditionInfographics, setConditionInfographics] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [sortDirection, setSortDirection] = useState("desc"); // 'asc' or 'desc'
//   const [filterRange, setFilterRange] = useState("all"); // 'today', 'last7', 'custom', 'all'
//   const [customStartDate, setCustomStartDate] = useState(null);
//   const [customEndDate, setCustomEndDate] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [editingItemId, setEditingItemId] = useState(null);
//   const [newName, setNewName] = useState("");

//   // Fetch infographics data
//   const fetchInfographics = async () => {
//     try {
//       let { data } = await getUserInfographics(); // Assuming it returns the array directly
//       data = data.infographics;
//       if (Array.isArray(data)) {
//         const sizeData = data.filter((item) => item.type.toLowerCase().includes("size"));
//         const conditionData = data.filter((item) => !item.type.toLowerCase().includes("size"));
//         setSizeInfographics(sizeData);
//         setConditionInfographics(conditionData);
//       }
//     } catch (err) {
//       console.error("Error fetching data:", err);
//     }
//   };

//   useEffect(() => {
//     fetchInfographics(); // Fetch data on initial load
//   }, []);

//   // Function to handle sorting
//   const handleSort = () => {
//     setSortDirection(sortDirection === "asc" ? "desc" : "asc");
//   };

//   // Handle Save Button in the parent component
//   const handleSave = async (id) => {
//     if (newName.trim() === "") {
//       alert("Name cannot be empty.");
//       return;
//     }

//     const dataToSend = {
//       id: id,
//       name: newName, // Updated name
//     };

//     console.log("Data to send:", dataToSend);
//     try {
//       // Call the API to update the infographic
//       const response = await updateInfographicById(id, dataToSend);
//       if (response) {
//         alert("Name updated successfully!");

//         // Refetch the data to refresh the table
//         await fetchInfographics();

//         setEditingItemId(null); // Reset editing mode
//         setNewName(""); // Clear the input
//       }
//     } catch (error) {
//       console.error("Failed to update the name", error);
//       alert("Failed to update the name.");
//     }
//   };

//   // Handle Delete Button in the parent component
//   const handleDelete = async (id) => {
//     try {
//       const response = await deleteInfographicById(id); // Call the delete API function
//       console.log("handleDelete_response:",response)
//       if (response) {
//         toast.success("Infographic deleted successfully!"); // Show success toast
  
//         // Remove the deleted infographic from the state (filtered out based on ID)
//         setSizeInfographics((prevData) => prevData.filter((item) => item.id !== id));
//         setConditionInfographics((prevData) => prevData.filter((item) => item.id !== id));
  
//         // Optionally, you can refetch the data from the backend if you want to ensure it's synchronized
//         // await fetchInfographics();
//       }
//     } catch (error) {
//       console.error("Failed to delete infographic", error);
//       toast.error("Failed to delete infographic."); // Show error toast
//     }
//   };

//   // Handle Edit Button in the parent component
//   const handleEdit = (id, currentName) => {
//     setEditingItemId(id); // Set the item as being edited
//     setNewName(currentName); // Set the current name as the new name in the input
//   };

//   return (
//     <div className="container mx-auto py-10">
//       <h1 className="text-3xl font-bold text-center mb-8">History</h1>

//       <Tab.Group>
//         <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 justify-center mb-8 w-full sm:w-1/2 mx-auto">
//           <Tab
//             className={({ selected }) =>
//               classNames(
//                 "w-full py-2.5 text-sm font-medium text-blue-700 rounded-lg",
//                 selected ? "bg-white shadow" : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
//               )
//             }
//           >
//             Condition Infographics
//           </Tab>
//           <Tab
//             className={({ selected }) =>
//               classNames(
//                 "w-full py-2.5 text-sm font-medium text-blue-700 rounded-lg",
//                 selected ? "bg-white shadow" : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
//               )
//             }
//           >
//             Size Charts
//           </Tab>
//         </Tab.List>

//         <Tab.Panels>
//           {/* Condition Tab */}
//           <Tab.Panel className="rounded-xl bg-white p-3">
//             <CommonTable
//               data={conditionInfographics}
//               currentPage={currentPage}
//               itemsPerPage={itemsPerPage}
//               setCurrentPage={setCurrentPage}
//               searchTerm={searchTerm}
//               handleSort={handleSort}
//               sortDirection={sortDirection}
//               filterRange={filterRange}
//               customStartDate={customStartDate}
//               customEndDate={customEndDate}
//               setCustomStartDate={setCustomStartDate}
//               setCustomEndDate={setCustomEndDate}
//               setItemsPerPage={setItemsPerPage}
//               setSearchTerm={setSearchTerm}
//               setFilterRange={setFilterRange}
//               editingItemId={editingItemId}
//               newName={newName}
//               setNewName={setNewName}
//               handleSave={handleSave} // Passing handleSave to CommonTable
//               handleEdit={handleEdit} // Passing handleEdit to CommonTable
//               handleDelete={handleDelete} // Passing handleDelete to CommonTable
//             />
//           </Tab.Panel>

//           {/* Size Tab */}
//           <Tab.Panel className="rounded-xl bg-white p-3">
//             <CommonTable
//               data={sizeInfographics}
//               currentPage={currentPage}
//               itemsPerPage={itemsPerPage}
//               setCurrentPage={setCurrentPage}
//               searchTerm={searchTerm}
//               handleSort={handleSort}
//               sortDirection={sortDirection}
//               filterRange={filterRange}
//               customStartDate={customStartDate}
//               customEndDate={customEndDate}
//               setCustomStartDate={setCustomStartDate}
//               setCustomEndDate={setCustomEndDate}
//               setItemsPerPage={setItemsPerPage}
//               setSearchTerm={setSearchTerm}
//               setFilterRange={setFilterRange}
//               editingItemId={editingItemId}
//               newName={newName}
//               setNewName={setNewName}
//               handleSave={handleSave} // Passing handleSave to CommonTable
//               handleEdit={handleEdit} // Passing handleEdit to CommonTable
//               handleDelete={handleDelete} // Passing handleDelete to CommonTable
//             />
//           </Tab.Panel>
//         </Tab.Panels>
//       </Tab.Group>
//     </div>
//   );
// };

// export default HistoryTimeline;
// import React, { useState, useEffect } from "react";
// import { Tab } from "@headlessui/react";
// import CommonTable from "./historyTable"; // Import the CommonTable component
// import { getUserInfographics, updateInfographicById } from "../../utils/apis/inforgraphic"; // Assuming you have the API function

// // Helper function to conditionally join class names
// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// const HistoryTimeline = () => {
//   const [sizeInfographics, setSizeInfographics] = useState([]);
//   const [conditionInfographics, setConditionInfographics] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [sortDirection, setSortDirection] = useState("desc"); // 'asc' or 'desc'
//   const [filterRange, setFilterRange] = useState("all"); // 'today', 'last7', 'custom', 'all'
//   const [customStartDate, setCustomStartDate] = useState(null);
//   const [customEndDate, setCustomEndDate] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [editingItemId, setEditingItemId] = useState(null);
//   const [newName, setNewName] = useState("");

//   // Fetch infographics data
//   useEffect(() => {
//     const fetchInfographics = async () => {
//       try {
//         let { data } = await getUserInfographics(); // Assuming it returns the array directly
//         data = data.infographics;
//         if (Array.isArray(data)) {
//           const sizeData = data.filter((item) => item.type.toLowerCase().includes("size"));
//           const conditionData = data.filter((item) => !item.type.toLowerCase().includes("size"));
//           setSizeInfographics(sizeData);
//           setConditionInfographics(conditionData);
//         }
//       } catch (err) {
//         console.error("Error fetching data:", err);
//       }
//     };
//     fetchInfographics();
//   }, []);

//   // Function to handle sorting
//   const handleSort = () => {
//     setSortDirection(sortDirection === "asc" ? "desc" : "asc");
//   };

//   // Handle Save Button in the parent component
//   const handleSave = async (id) => {
//     if (newName.trim() === "") {
//       alert("Name cannot be empty.");
//       return;
//     }

//     const dataToSend = {
//       id: id,
//       name: newName, // Updated name
//     };

//     console.log("Data to send:", dataToSend);
//     try {
//       // Call the API to update the infographic
//       const response = await updateInfographicById(id, dataToSend);
//       if (response) {
//         alert("Name updated successfully!");
//         setEditingItemId(null); // Reset editing mode
//         setNewName(""); // Clear the input
//       }
//     } catch (error) {
//       console.error("Failed to update the name", error);
//       alert("Failed to update the name.");
//     }
//   };

//   // Handle Edit Button in the parent component
//   const handleEdit = (id, currentName) => {
//     setEditingItemId(id); // Set the item as being edited
//     setNewName(currentName); // Set the current name as the new name in the input
//   };

//   return (
//     <div className="container mx-auto py-10">
//       <h1 className="text-3xl font-bold text-center mb-8">History</h1>

//       <Tab.Group>
//         <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 justify-center mb-8 w-full sm:w-1/2 mx-auto">
//           <Tab
//             className={({ selected }) =>
//               classNames(
//                 "w-full py-2.5 text-sm font-medium text-blue-700 rounded-lg",
//                 selected ? "bg-white shadow" : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
//               )
//             }
//           >
//             Condition Infographics
//           </Tab>
//           <Tab
//             className={({ selected }) =>
//               classNames(
//                 "w-full py-2.5 text-sm font-medium text-blue-700 rounded-lg",
//                 selected ? "bg-white shadow" : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
//               )
//             }
//           >
//             Size Charts
//           </Tab>
//         </Tab.List>

//         <Tab.Panels>
//           {/* Condition Tab */}
//           <Tab.Panel className="rounded-xl bg-white p-3">
//             <CommonTable
//               data={conditionInfographics}
//               currentPage={currentPage}
//               itemsPerPage={itemsPerPage}
//               setCurrentPage={setCurrentPage}
//               searchTerm={searchTerm}
//               handleSort={handleSort}
//               sortDirection={sortDirection}
//               filterRange={filterRange}
//               customStartDate={customStartDate}
//               customEndDate={customEndDate}
//               setCustomStartDate={setCustomStartDate}
//               setCustomEndDate={setCustomEndDate}
//               setItemsPerPage={setItemsPerPage}
//               setSearchTerm={setSearchTerm}
//               setFilterRange={setFilterRange}
//               editingItemId={editingItemId}
//               newName={newName}
//               setNewName={setNewName}
//               handleSave={handleSave} // Passing handleSave to CommonTable
//               handleEdit={handleEdit} // Passing handleEdit to CommonTable
//             />
//           </Tab.Panel>

//           {/* Size Tab */}
//           <Tab.Panel className="rounded-xl bg-white p-3">
//             <CommonTable
//               data={sizeInfographics}
//               currentPage={currentPage}
//               itemsPerPage={itemsPerPage}
//               setCurrentPage={setCurrentPage}
//               searchTerm={searchTerm}
//               handleSort={handleSort}
//               sortDirection={sortDirection}
//               filterRange={filterRange}
//               customStartDate={customStartDate}
//               customEndDate={customEndDate}
//               setCustomStartDate={setCustomStartDate}
//               setCustomEndDate={setCustomEndDate}
//               setItemsPerPage={setItemsPerPage}
//               setSearchTerm={setSearchTerm}
//               setFilterRange={setFilterRange}
//               editingItemId={editingItemId}
//               newName={newName}
//               setNewName={setNewName}
//               handleSave={handleSave} // Passing handleSave to CommonTable
//               handleEdit={handleEdit} // Passing handleEdit to CommonTable
//             />
//           </Tab.Panel>
//         </Tab.Panels>
//       </Tab.Group>
//     </div>
//   );
// };

// export default HistoryTimeline;