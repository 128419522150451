// src/api.js
import axios from "axios";

// Create an Axios instance
const api = axios.create({
  // baseURL: `http://localhost:8015/api/v1`, // Replace with your backend URL
  baseURL: `https://backend.illuscriber.com/api/v1`, // Replace with your backend URL
  withCredentials: true, // Include cookies if needed
});

// Request interceptor to add auth token to headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken"); // Retrieve accessToken from localStorage
    console.log("Access Token:", token);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // You can handle global errors here
    return Promise.reject(error);
  }
);

export default api;
