
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FaHistory } from "react-icons/fa"; // Example icon for History
import { FiSettings } from "react-icons/fi"; // Example icon for Account Info
import { AiOutlineTool } from "react-icons/ai"; // Example icon for Tool Settings
import { MdSubscriptions } from "react-icons/md"; // Example icon for Subscription
import { BiLogOut } from "react-icons/bi"; // Example icon for Sign-Out

const Sidebar = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const [selectedLinkedIn, setSelectedLinkedIn] = useState("");
  const [linkedInAccountData, setLinkedInAccountData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
      window.location.reload();
      toast.success("Logged out successfully");
    } catch (err) {
      toast.error("Logout failed. Please try again.");
    }
  };

  useEffect(() => {
    // Your existing logic
  }, [user, navigate]);

  return (
    <div className="flex">
      <aside
        id="logo-sidebar"
        className="h-screen sticky top-0 left-0 z-40 w-72 pt-5 bg-gray-50 border-r border-gray-200 shadow-lg transition-transform sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full px-4 pb-4 overflow-y-auto">
          <ul className="space-y-3">
            <li>
              <img
                src={"/assets/images/Logo/Logo.png"}
                alt="Logo"
                className="w-32 mx-auto mb-6"
              />
            </li>

            {/* New Navigation Links */}
            <li>
              <Link
                to="/dashboard/history-timeline"
                className={`flex w-full px-4 py-3 text-sm font-semibold text-black rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out items-center ${
                  location.pathname === "/dashboard/history-timeline"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <FaHistory size={25} className="mr-3" />
                History / Timeline
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/settings"
                className={`flex w-full px-4 py-3 text-sm font-semibold text-black rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out items-center ${
                  location.pathname === "/dashboard/settings"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <FiSettings size={25} className="mr-3" />
                Account Info
              </Link>
            </li>
            {/* <li>
              <Link
                to="/dashboard/tool-settings"
                className={`flex w-full px-4 py-3 text-sm font-semibold text-black rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out items-center ${
                  location.pathname === "/dashboard/tool-settings"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <AiOutlineTool size={25} className="mr-3" />
                Tool Settings
              </Link>
            </li> */}
            <li>
              <Link
                to="/dashboard/subscription"
                className={`flex w-full px-4 py-3 text-sm font-semibold text-black rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out items-center ${
                  location.pathname === "/dashboard/subscription"
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                <MdSubscriptions size={25} className="mr-3" />
                Subscription
              </Link>
            </li>
            <li>
              <div
                onClick={handleLogout}
                className={`flex w-full px-4 py-3 text-sm font-semibold text-black rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out items-center cursor-pointer`}
              >
                <BiLogOut size={25} className="mr-3" />
                Sign-Out
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <main className="flex-1 p-6">
        {children}
      </main>
    </div>
  );
};

export default Sidebar;