import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getInvoices } from "../utils/apis/subscriptionService";

const Invoices = (userId) => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        // Fetch invoices using the getInvoices function from the subscription service
        const invoiceData = await getInvoices(userId); // Pass user ID to get invoices
        setInvoices(invoiceData); // Update state with fetched invoices
      } catch (err) {
        console.error(err);
        toast.error("Failed to fetch invoices.");
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    fetchInvoices();
  }, []);

  if (loading) {
    return <p>Loading invoices...</p>;
  }

  if (invoices.length === 0) {
    return <p>No invoices found.</p>;
  }

  return (
    <div className="max-w-2xl mx-auto bg-white p-6 rounded-md shadow-md mt-6">
      <h2 className="text-xl font-semibold mb-4">Invoices</h2>
      <ul>
        {invoices.map((invoice) => (
          <li key={invoice.id} className="mb-2">
            <div className="flex justify-between">
              <span>Invoice #{invoice.number}</span>
              <span>{new Date(invoice.created).toLocaleDateString()}</span>
            </div>
            <div className="flex justify-between">
              <span>Total:</span>
              <span>${(invoice.amount_due / 100).toFixed(2)}</span>
            </div>
            <div className="flex justify-end">
              <a
                href={invoice.pdf_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Download PDF
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Invoices;