// src/services/condition.js
import api from "./api"; // Assuming you have an API setup like in userService.js


// Function to create an infography
export const getUserInfographics = async () => {
  try {
    console.log("getUserInfographics");
    const response = await api.get("/infographics/user/all");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getInfographicById = async (id) => {
  try {
    console.log("getInfographicById", id);
    const response = await api.get(`/infographics/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateInfographicById = async (id,data) => {
  try {
    console.log("updateInfographicById", id, data);
    const response = await api.patch(`/infographics/${id}`,data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteInfographicById = async (id) => {
    try {
      const response = await api.delete(`/infographics/${id}`);
      return response.data; // This should contain status and message from the backend
    } catch (error) {
      console.error("Error in delete API:", error);
      throw error.response ? error.response.data : error;
    }
  };