import React, { useState } from "react";
import { toast } from "react-toastify";
import ConfirmModal from "./ConfirmModal"; // Assuming ConfirmModal is another component
import { cancelSubscription } from "../utils/apis/subscriptionService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../redux/slices/authSlice";

const CancelSubscription = ({ subscriptionId }) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCancel = async () => {
    setLoading(true);

    try {
      // Call the cancelSubscription function from the service
      const response = await cancelSubscription(subscriptionId);
      console.log("response.success:",response.success)
        toast.success("Subscription canceled successfully.");
        // Fetch user data after successful subscription creation
      dispatch(fetchUser()); // Fetch user data
      navigate("/history-timeline")

    } catch (err) {
      console.error(err);
      toast.error("An error occurred. Please try again.");
    }

    setLoading(false);
    closeModal();
  };

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg border border-gray-200">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Cancel Subscription</h2>
      <p className="text-sm text-gray-600 mb-4">
        Are you sure you want to cancel your subscription? This action is permanent.
      </p>

      <button
        onClick={openModal}
        disabled={loading}
        className={`w-full py-2 px-4 rounded-md text-white text-lg font-semibold focus:outline-none ${
          loading ? "bg-gray-400 cursor-not-allowed" : "bg-red-600 hover:bg-red-700"
        }`}
      >
        {loading ? "Cancelling..." : "Cancel Subscription"}
      </button>

      <ConfirmModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onConfirm={handleCancel}
        message="Are you sure you want to cancel your subscription? This action is permanent."
      />
    </div>
  );
};

export default CancelSubscription;