import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import authReducer from "./slices/authSlice"; // Import the auth slice
import { useSelector } from "react-redux"; // Import useSelector

// Define persist config for both auth and condition slices
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Only persist the 'auth' slice
};

// Use persistReducer for the auth slice only
const persistedReducer = persistReducer(persistConfig, authReducer);

// Create the store
export const store = configureStore({
  reducer: {
    auth: persistedReducer, // 'auth' is persisted
  },
  // Ignore certain redux-persist actions in serializable check
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "persist/REHYDRATE",
          "persist/FLUSH",
          "persist/PAUSE",
          "persist/PURGE",
          "persist/REGISTER",
        ],
      },
    }),
});

// Persist the store
export const persistor = persistStore(store);

// Create the custom useAppSelector hook for accessing the state
export const useAppSelector = useSelector;