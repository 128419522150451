import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "react-toastify";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5"; // Importing the eye icons
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../redux/slices/authSlice"; // Redux action to fetch user
import { updateMe, updateDefualtSizeMeasuringUnit } from "../../utils/apis/userService";
import { updatePassword } from "../../utils/apis/authService";

const profileFormSchema = z.object({
  name: z.string().min(3, {
    message: "Name must be at least 3 characters.",
  }),
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
});

const passwordFormSchema = z.object({
  oldPassword: z.string().min(8, {
    message: "Password must be at least 8 characters.",
  }),
  newPassword: z
    .string()
    .min(8, {
      message: "Password must be at least 8 characters.",
    })
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, {
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, and one special character.",
    }),
  confirmPassword: z.string(),
}).refine((data) => data.newPassword === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

export default function AccountInfo() {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.auth);

  // Load user data when component mounts
  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [user, dispatch]);

  // Conditionally initialize the form after the user data is fetched
  const profileForm = useForm({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      name: user?.name || "",
      email: user?.email || "",
    },
  });

  // Update the form values whenever the user data changes
  useEffect(() => {
    if (user) {
      profileForm.reset({
        name: user.name || "",
        email: user.email || "",
      });
    }
  }, [user, profileForm]);

  // Password change form state
  const passwordForm = useForm({
    resolver: zodResolver(passwordFormSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  // Password visibility states
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // New section state for Size Tool Settings
  const [selectedUnit, setSelectedUnit] = useState("INCHE"); // Default unit is inches

  // Handle the unit toggle change and update the default size measuring unit
  const handleUnitChange = (unit) => {
    setSelectedUnit(unit);
    updateDefualtSizeMeasuringUnit(unit); // Call the function to update the measuring unit in the backend
  };

  // Handle profile form submission to update profile
  const onProfileSubmit = async (data) => {
    const { name, email } = data;
    try {
      const result = await updateMe(name, email); // Update the profile with name and email
      toast.success(result.message || "Profile updated successfully!");
      profileForm.reset(); // Reset profile form fields after successful update
    } catch (error) {
      toast.error(error.message || "Failed to update profile.");
    }
  };

  // Handle password change form submission
  const onPasswordSubmit = async (data) => {
    const { oldPassword, newPassword } = data;

    try {
      // Call the updatePassword API function
      const result = await updatePassword(oldPassword, newPassword);

      // Show success toast
      toast.success(result.message || "Password updated successfully!");

      // Reset form fields if needed
      passwordForm.reset();
    } catch (error) {
      // Show error toast if something goes wrong
      toast.error(error.message || "Failed to update password.");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading user data. Please refresh.</p>;

  return (
    <div className="space-y-8">
      {/* First Row: Profile Update & Change Password */}
      <div className="flex space-x-8">
        {/* Profile Update Section */}
        <div className="flex-1 p-12 bg-white shadow rounded-md">
          <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Update Profile</h2>
          <p className="text-gray-600 mb-6">Update your account information here.</p>

          {/* Render form only after user data is available */}
          {user && (
            <form onSubmit={profileForm.handleSubmit(onProfileSubmit)} className="space-y-4">
              <div className="flex items-center space-x-4">
                <label htmlFor="name" className="block text-gray-700 text-sm w-[30%]">Name</label>
                <input
                  type="text"
                  id="name"
                  {...profileForm.register("name")}
                  className={`w-[70%] px-3 py-2 border ${
                    profileForm.formState.errors.name ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
                />
              </div>
              {profileForm.formState.errors.name && (
                <p className="text-red-500 text-sm">{profileForm.formState.errors.name.message}</p>
              )}

              <div className="flex items-center space-x-4">
                <label htmlFor="email" className="block text-gray-700 text-sm w-[30%]">Email</label>
                <input
                  type="email"
                  id="email"
                  {...profileForm.register("email")}
                  className={`w-[70%] px-3 py-2 border ${
                    profileForm.formState.errors.email ? "border-red-500" : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
                />
              </div>
              {profileForm.formState.errors.email && (
                <p className="text-red-500 text-sm">{profileForm.formState.errors.email.message}</p>
              )}

              <button
                type="submit"
                className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
              >
                Update Profile
              </button>
            </form>
          )}
        </div>

        {/* Password Change Section */}
        <div className="flex-1 p-12 bg-white shadow rounded-md">
          <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Change Password</h2>
          <p className="text-gray-600 mb-6">Update your password here. Please make sure it's secure.</p>
          <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)} className="space-y-4">
            {/* Old Password Field */}
            <div className="relative flex items-center space-x-4">
              <label htmlFor="oldPassword" className="block text-gray-700 text-sm w-[30%]">Old Password</label>
              <input
                type={showOldPassword ? "text" : "password"}
                id="oldPassword"
                {...passwordForm.register("oldPassword")}
                className={`w-[70%] px-3 py-2 border ${
                  passwordForm.formState.errors.oldPassword ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
                onClick={() => setShowOldPassword(!showOldPassword)}
                aria-label={showOldPassword ? "Hide password" : "Show password"}
              >
                {showOldPassword ? (
                  <IoEyeOffOutline size={20} />
                ) : (
                  <IoEyeOutline size={20} />
                )}
              </button>
            </div>

            {/* New Password Field */}
            <div className="relative flex items-center space-x-4">
              <label htmlFor="newPassword" className="block text-gray-700 text-sm w-[30%]">New Password</label>
              <input
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                {...passwordForm.register("newPassword")}
                className={`w-[70%] px-3 py-2 border ${
                  passwordForm.formState.errors.newPassword ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
                onClick={() => setShowNewPassword(!showNewPassword)}
                aria-label={showNewPassword ? "Hide password" : "Show password"}
              >
                {showNewPassword ? (
                  <IoEyeOffOutline size={20} />
                ) : (
                  <IoEyeOutline size={20} />
                )}
              </button>
            </div>

            {/* Confirm New Password Field */}
            <div className="relative flex items-center space-x-4">
              <label htmlFor="confirmPassword" className="block text-gray-700 text-sm w-[30%]">Confirm New Password</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                {...passwordForm.register("confirmPassword")}
                className={`w-[70%] px-3 py-2 border ${
                  passwordForm.formState.errors.confirmPassword ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                aria-label={showConfirmPassword ? "Hide password" : "Show password"}
              >
                {showConfirmPassword ? (
                  <IoEyeOffOutline size={20} />
                ) : (
                  <IoEyeOutline size={20} />
                )}
              </button>
            </div>

            <button
              type="submit"
              className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>

      {/* Second Row: Tool Settings */}
      <div className="p-12 bg-white shadow rounded-md">
        <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Tool Settings</h2>
        <p className="text-gray-600 mb-6">Configure your size tool settings here.</p>

        <div className="flex space-x-8">
          <div className="flex items-center space-x-4">
            <label htmlFor="INCHE" className="text-sm text-gray-700">Inches</label>
            <input
              type="radio"
              id="INCHE"
              name="unit"
              value="INCHE"
              checked={selectedUnit === "INCHE"}
              onChange={() => handleUnitChange("INCHE")}
            />
          </div>

          <div className="flex items-center space-x-4">
            <label htmlFor="CENTIMETER" className="text-sm text-gray-700">Centimeters</label>
            <input
              type="radio"
              id="CENTIMETER"
              name="unit"
              value="CENTIMETER"
              checked={selectedUnit === "CENTIMETER"}
              onChange={() => handleUnitChange("CENTIMETER")}
            />
          </div>
          {/* <div className="flex items-center space-x-4">
            <label htmlFor="ALL" className="text-sm text-gray-700">ALL</label>
            <input
              type="radio"
              id="ALL"
              name="unit"
              value="ALL"
              checked={selectedUnit === "ALL"}
              onChange={() => handleUnitChange("ALL")}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
// import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
// import { zodResolver } from "@hookform/resolvers/zod";
// import * as z from "zod";
// import { toast } from "react-toastify";
// import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5"; // Importing the eye icons
// import { useDispatch, useSelector } from "react-redux";
// import { fetchUser } from "../../redux/slices/authSlice"; // Redux action to fetch user
// import { updateMe,updateDefualtSizeMeasuringUnit } from "../../utils/apis/userService";
// import { updatePassword } from "../../utils/apis/authService";

// const profileFormSchema = z.object({
//   name: z.string().min(3, {
//     message: "Name must be at least 3 characters.",
//   }),
//   email: z.string().email({
//     message: "Please enter a valid email address.",
//   }),
// });

// const passwordFormSchema = z.object({
//   oldPassword: z.string().min(8, {
//     message: "Password must be at least 8 characters.",
//   }),
//   newPassword: z
//     .string()
//     .min(8, {
//       message: "Password must be at least 8 characters.",
//     })
//     .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, {
//       message:
//         "Password must contain at least one uppercase letter, one lowercase letter, and one special character.",
//     }),
//   confirmPassword: z.string(),
// }).refine((data) => data.newPassword === data.confirmPassword, {
//   message: "Passwords don't match",
//   path: ["confirmPassword"],
// });

// export default function AccountInfo() {
//   const dispatch = useDispatch();
//   const { user, loading, error } = useSelector((state) => state.auth);

//   // Load user data when component mounts
//   useEffect(() => {
//     if (!user) {
//       dispatch(fetchUser());
//     }
//   }, [user, dispatch]);

//   // Conditionally initialize the form after the user data is fetched
//   const profileForm = useForm({
//     resolver: zodResolver(profileFormSchema),
//     defaultValues: {
//       name: user?.name || "",
//       email: user?.email || "",
//     },
//   });

//   // Update the form values whenever the user data changes
//   useEffect(() => {
//     if (user) {
//       profileForm.reset({
//         name: user.name || "",
//         email: user.email || "",
//       });
//     }
//   }, [user, profileForm]);

//   // Password change form state
//   const passwordForm = useForm({
//     resolver: zodResolver(passwordFormSchema),
//     defaultValues: {
//       oldPassword: "",
//       newPassword: "",
//       confirmPassword: "",
//     },
//   });

//   // Password visibility states
//   const [showOldPassword, setShowOldPassword] = useState(false);
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   // New section state for Size Tool Settings
//   const [selectedUnit, setSelectedUnit] = useState("inches"); // Default unit is inches

//   // Handle profile form submission to update profile
//   const onProfileSubmit = async (data) => {
//     const { name, email } = data;
//     try {
//       const result = await updateMe(name, email); // Update the profile with name and email
//       toast.success(result.message || "Profile updated successfully!");
//       profileForm.reset(); // Reset profile form fields after successful update
//     } catch (error) {
//       toast.error(error.message || "Failed to update profile.");
//     }
//   };

//   // Handle password change form submission
//   const onPasswordSubmit = async (data) => {
//     const { oldPassword, newPassword } = data;

//     try {
//       // Call the updatePassword API function
//       const result = await updatePassword(oldPassword, newPassword);

//       // Show success toast
//       toast.success(result.message || "Password updated successfully!");

//       // Reset form fields if needed
//       passwordForm.reset();
//     } catch (error) {
//       // Show error toast if something goes wrong
//       toast.error(error.message || "Failed to update password.");
//     }
//   };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error loading user data. Please refresh.</p>;

//   return (
//     <div className="space-y-8">
//       {/* First Row: Profile Update & Change Password */}
//       <div className="flex space-x-8">
//         {/* Profile Update Section */}
//         <div className="flex-1 p-12 bg-white shadow rounded-md">
//           <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Update Profile</h2>
//           <p className="text-gray-600 mb-6">Update your account information here.</p>

//           {/* Render form only after user data is available */}
//           {user && (
//             <form onSubmit={profileForm.handleSubmit(onProfileSubmit)} className="space-y-4">
//               <div className="flex items-center space-x-4">
//                 <label htmlFor="name" className="block text-gray-700 text-sm w-[30%]">Name</label>
//                 <input
//                   type="text"
//                   id="name"
//                   {...profileForm.register("name")}
//                   className={`w-[70%] px-3 py-2 border ${
//                     profileForm.formState.errors.name ? "border-red-500" : "border-gray-300"
//                   } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//                 />
//               </div>
//               {profileForm.formState.errors.name && (
//                 <p className="text-red-500 text-sm">{profileForm.formState.errors.name.message}</p>
//               )}

//               <div className="flex items-center space-x-4">
//                 <label htmlFor="email" className="block text-gray-700 text-sm w-[30%]">Email</label>
//                 <input
//                   type="email"
//                   id="email"
//                   {...profileForm.register("email")}
//                   className={`w-[70%] px-3 py-2 border ${
//                     profileForm.formState.errors.email ? "border-red-500" : "border-gray-300"
//                   } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//                 />
//               </div>
//               {profileForm.formState.errors.email && (
//                 <p className="text-red-500 text-sm">{profileForm.formState.errors.email.message}</p>
//               )}

//               <button
//                 type="submit"
//                 className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//               >
//                 Update Profile
//               </button>
//             </form>
//           )}
//         </div>

//         {/* Password Change Section */}
//         <div className="flex-1 p-12 bg-white shadow rounded-md">
//           <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Change Password</h2>
//           <p className="text-gray-600 mb-6">Update your password here. Please make sure it's secure.</p>
//           <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)} className="space-y-4">
//             {/* Old Password Field */}
//             <div className="relative flex items-center space-x-4">
//               <label htmlFor="oldPassword" className="block text-gray-700 text-sm w-[30%]">Old Password</label>
//               <input
//                 type={showOldPassword ? "text" : "password"}
//                 id="oldPassword"
//                 {...passwordForm.register("oldPassword")}
//                 className={`w-[70%] px-3 py-2 border ${
//                   passwordForm.formState.errors.oldPassword ? "border-red-500" : "border-gray-300"
//                 } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//               />
//               <button
//                 type="button"
//                 className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//                 onClick={() => setShowOldPassword(!showOldPassword)}
//                 aria-label={showOldPassword ? "Hide password" : "Show password"}
//               >
//                 {showOldPassword ? (
//                   <IoEyeOffOutline size={20} />
//                 ) : (
//                   <IoEyeOutline size={20} />
//                 )}
//               </button>
//             </div>

//             {/* New Password Field */}
//             <div className="relative flex items-center space-x-4">
//               <label htmlFor="newPassword" className="block text-gray-700 text-sm w-[30%]">New Password</label>
//               <input
//                 type={showNewPassword ? "text" : "password"}
//                 id="newPassword"
//                 {...passwordForm.register("newPassword")}
//                 className={`w-[70%] px-3 py-2 border ${
//                   passwordForm.formState.errors.newPassword ? "border-red-500" : "border-gray-300"
//                 } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//               />
//               <button
//                 type="button"
//                 className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//                 onClick={() => setShowNewPassword(!showNewPassword)}
//                 aria-label={showNewPassword ? "Hide password" : "Show password"}
//               >
//                 {showNewPassword ? (
//                   <IoEyeOffOutline size={20} />
//                 ) : (
//                   <IoEyeOutline size={20} />
//                 )}
//               </button>
//             </div>

//             {/* Confirm New Password Field */}
//             <div className="relative flex items-center space-x-4">
//               <label htmlFor="confirmPassword" className="block text-gray-700 text-sm w-[30%]">Confirm New Password</label>
//               <input
//                 type={showConfirmPassword ? "text" : "password"}
//                 id="confirmPassword"
//                 {...passwordForm.register("confirmPassword")}
//                 className={`w-[70%] px-3 py-2 border ${
//                   passwordForm.formState.errors.confirmPassword ? "border-red-500" : "border-gray-300"
//                 } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//               />
//               <button
//                 type="button"
//                 className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//                 onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                 aria-label={showConfirmPassword ? "Hide password" : "Show password"}
//               >
//                 {showConfirmPassword ? (
//                   <IoEyeOffOutline size={20} />
//                 ) : (
//                   <IoEyeOutline size={20} />
//                 )}
//               </button>
//             </div>

//             <button
//               type="submit"
//               className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//             >
//               Change Password
//             </button>
//           </form>
//         </div>
//       </div>

//       {/* Second Row: Tool Settings */}
//       <div className="p-12 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Tool Settings</h2>
//         <p className="text-gray-600 mb-6">Configure your size tool settings here.</p>

//         <div className="flex space-x-8">
//           <div className="flex items-center space-x-4">
//             <label htmlFor="inches" className="text-sm text-gray-700">Inches</label>
//             <input
//               type="radio"
//               id="inches"
//               name="unit"
//               value="inches"
//               checked={selectedUnit === "inches"}
//               onChange={() => setSelectedUnit("inches")}
//             />
//           </div>

//           <div className="flex items-center space-x-4">
//             <label htmlFor="centimeters" className="text-sm text-gray-700">Centimeters</label>
//             <input
//               type="radio"
//               id="centimeters"
//               name="unit"
//               value="centimeters"
//               checked={selectedUnit === "centimeters"}
//               onChange={() => setSelectedUnit("centimeters")}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
// import { zodResolver } from "@hookform/resolvers/zod";
// import * as z from "zod";
// import { toast } from "react-toastify";
// import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5"; // Importing the eye icons
// import { useDispatch, useSelector } from "react-redux";
// import { fetchUser } from "../../redux/slices/authSlice"; // Redux action to fetch user
// import { updateMe } from "../../utils/apis/userService";
// import { updatePassword } from "../../utils/apis/authService";

// const profileFormSchema = z.object({
//   name: z.string().min(3, {
//     message: "Name must be at least 3 characters.",
//   }),
//   email: z.string().email({
//     message: "Please enter a valid email address.",
//   }),
// });

// const passwordFormSchema = z.object({
//   oldPassword: z.string().min(8, {
//     message: "Password must be at least 8 characters.",
//   }),
//   newPassword: z
//     .string()
//     .min(8, {
//       message: "Password must be at least 8 characters.",
//     })
//     .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, {
//       message:
//         "Password must contain at least one uppercase letter, one lowercase letter, and one special character.",
//     }),
//   confirmPassword: z.string(),
// }).refine((data) => data.newPassword === data.confirmPassword, {
//   message: "Passwords don't match",
//   path: ["confirmPassword"],
// });

// export default function AccountInfo() {
//   const dispatch = useDispatch();
//   const { user, loading, error } = useSelector((state) => state.auth);

//   // Load user data when component mounts
//   useEffect(() => {
//     if (!user) {
//       dispatch(fetchUser());
//     }
//   }, [user, dispatch]);

//   // Conditionally initialize the form after the user data is fetched
//   const profileForm = useForm({
//     resolver: zodResolver(profileFormSchema),
//     defaultValues: {
//       name: user?.name || "",
//       email: user?.email || "",
//     },
//   });

//   // Update the form values whenever the user data changes
//   useEffect(() => {
//     if (user) {
//       profileForm.reset({
//         name: user.name || "",
//         email: user.email || "",
//       });
//     }
//   }, [user, profileForm]);

//   // Password change form state
//   const passwordForm = useForm({
//     resolver: zodResolver(passwordFormSchema),
//     defaultValues: {
//       oldPassword: "",
//       newPassword: "",
//       confirmPassword: "",
//     },
//   });

//   // Password visibility states
//   const [showOldPassword, setShowOldPassword] = useState(false);
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   // Handle profile form submission to update profile
//   const onProfileSubmit = async (data) => {
//     const { name, email } = data;
//     try {
//       const result = await updateMe(name, email); // Update the profile with name and email
//       toast.success(result.message || "Profile updated successfully!");
//       profileForm.reset(); // Reset profile form fields after successful update
//     } catch (error) {
//       toast.error(error.message || "Failed to update profile.");
//     }
//   };

//   // Handle password change form submission
//   const onPasswordSubmit = async (data) => {
//     const { oldPassword, newPassword } = data;

//     try {
//       // Call the updatePassword API function
//       const result = await updatePassword(oldPassword, newPassword);

//       // Show success toast
//       toast.success(result.message || "Password updated successfully!");

//       // Reset form fields if needed
//       passwordForm.reset();
//     } catch (error) {
//       // Show error toast if something goes wrong
//       toast.error(error.message || "Failed to update password.");
//     }
//   };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error loading user data. Please refresh.</p>;

//   return (
//     <div className="flex flex-row space-x-8">
//       {/* Profile Update Section */}
//       <div className="flex-1 p-12 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Update Profile</h2>
//         <p className="text-gray-600 mb-6">Update your account information here.</p>

//         {/* Render form only after user data is available */}
//         {user && (
//           <form onSubmit={profileForm.handleSubmit(onProfileSubmit)} className="space-y-4">
//             <div className="flex items-center space-x-4">
//               <label htmlFor="name" className="block text-gray-700 text-sm w-[30%]">Name</label>
//               <input
//                 type="text"
//                 id="name"
//                 {...profileForm.register("name")}
//                 className={`w-[70%] px-3 py-2 border ${
//                   profileForm.formState.errors.name ? "border-red-500" : "border-gray-300"
//                 } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//               />
//             </div>
//             {profileForm.formState.errors.name && (
//               <p className="text-red-500 text-sm">{profileForm.formState.errors.name.message}</p>
//             )}

//             <div className="flex items-center space-x-4">
//               <label htmlFor="email" className="block text-gray-700 text-sm w-[30%]">Email</label>
//               <input
//                 type="email"
//                 id="email"
//                 {...profileForm.register("email")}
//                 className={`w-[70%] px-3 py-2 border ${
//                   profileForm.formState.errors.email ? "border-red-500" : "border-gray-300"
//                 } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//               />
//             </div>
//             {profileForm.formState.errors.email && (
//               <p className="text-red-500 text-sm">{profileForm.formState.errors.email.message}</p>
//             )}

//             <button
//               type="submit"
//               className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//             >
//               Update Profile
//             </button>
//           </form>
//         )}
//       </div>

//       {/* Password Change Section */}
//       <div className="flex-1 p-12 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Change Password</h2>
//         <p className="text-gray-600 mb-6">Update your password here. Please make sure it's secure.</p>
//         <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)} className="space-y-4">
//           {/* Old Password Field */}
//           <div className="relative flex items-center space-x-4">
//             <label htmlFor="oldPassword" className="block text-gray-700 text-sm w-[30%]">Old Password</label>
//             <input
//               type={showOldPassword ? "text" : "password"}
//               id="oldPassword"
//               {...passwordForm.register("oldPassword")}
//               className={`w-[70%] px-3 py-2 border ${
//                 passwordForm.formState.errors.oldPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//             />
//             <button
//               type="button"
//               className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//               onClick={() => setShowOldPassword(!showOldPassword)}
//               aria-label={showOldPassword ? "Hide password" : "Show password"}
//             >
//               {showOldPassword ? (
//                 <IoEyeOffOutline size={20} />
//               ) : (
//                 <IoEyeOutline size={20} />
//               )}
//             </button>
//           </div>

//           {/* New Password Field */}
//           <div className="relative flex items-center space-x-4">
//             <label htmlFor="newPassword" className="block text-gray-700 text-sm w-[30%]">New Password</label>
//             <input
//               type={showNewPassword ? "text" : "password"}
//               id="newPassword"
//               {...passwordForm.register("newPassword")}
//               className={`w-[70%] px-3 py-2 border ${
//                 passwordForm.formState.errors.newPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//             />
//             <button
//               type="button"
//               className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//               onClick={() => setShowNewPassword(!showNewPassword)}
//               aria-label={showNewPassword ? "Hide password" : "Show password"}
//             >
//               {showNewPassword ? (
//                 <IoEyeOffOutline size={20} />
//               ) : (
//                 <IoEyeOutline size={20} />
//               )}
//             </button>
//           </div>

//           {/* Confirm New Password Field */}
//           <div className="relative flex items-center space-x-4">
//             <label htmlFor="confirmPassword" className="block text-gray-700 text-sm w-[30%]">Confirm New Password</label>
//             <input
//               type={showConfirmPassword ? "text" : "password"}
//               id="confirmPassword"
//               {...passwordForm.register("confirmPassword")}
//               className={`w-[70%] px-3 py-2 border ${
//                 passwordForm.formState.errors.confirmPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//             />
//             <button
//               type="button"
//               className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//               onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//               aria-label={showConfirmPassword ? "Hide password" : "Show password"}
//             >
//               {showConfirmPassword ? (
//                 <IoEyeOffOutline size={20} />
//               ) : (
//                 <IoEyeOutline size={20} />
//               )}
//             </button>
//           </div>

//           <button
//             type="submit"
//             className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//           >
//             Change Password
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }
// import React, { useState } from "react";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { useForm } from "react-hook-form";
// import * as z from "zod";
// import { toast } from "react-toastify";
// import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5"; // Importing the eye icons
// import { updatePassword } from "../../utils/apis/authService";
// import { updateMe } from "../../utils/apis/userService";

// const profileFormSchema = z.object({
//   name: z.string().min(3, {
//     message: "Name must be at least 3 characters.",
//   }),
//   email: z.string().email({
//     message: "Please enter a valid email address.",
//   }),
// });

// const passwordFormSchema = z.object({
//   oldPassword: z.string().min(8, {
//     message: "Password must be at least 8 characters.",
//   }),
//   newPassword: z
//     .string()
//     .min(8, {
//       message: "Password must be at least 8 characters.",
//     })
//     .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, {
//       message:
//         "Password must contain at least one uppercase letter, one lowercase letter, and one special character.",
//     }),
//   confirmPassword: z.string(),
// }).refine((data) => data.newPassword === data.confirmPassword, {
//   message: "Passwords don't match",
//   path: ["confirmPassword"],
// });

// export default function AccountInfo() {
//   // Profile form state
//   const profileForm = useForm({
//     resolver: zodResolver(profileFormSchema),
//     defaultValues: {
//       name: "John Doe",
//       email: "john@example.com",
//     },
//   });

//   // Password change form state
//   const passwordForm = useForm({
//     resolver: zodResolver(passwordFormSchema),
//     defaultValues: {
//       oldPassword: "",
//       newPassword: "",
//       confirmPassword: "",
//     },
//   });

//   // Password visibility states
//   const [showOldPassword, setShowOldPassword] = useState(false);
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   // Handle profile form submission to update profile
//   const onProfileSubmit = async (data) => {
//     const { name, email } = data;
//     try {
//       const result = await updateMe(name, email); // Update the profile with name and email
//       toast.success(result.message || "Profile updated successfully!");
//       profileForm.reset(); // Reset profile form fields after successful update
//     } catch (error) {
//       toast.error(error.message || "Failed to update profile.");
//     }
//   };

//   // Handle password change form submission
//   const onPasswordSubmit = async (data) => {
//     const { oldPassword, newPassword } = data;

//     try {
//       // Call the updatePassword API function
//       const result = await updatePassword(oldPassword, newPassword);

//       // Show success toast
//       toast.success(result.message || "Password updated successfully!");

//       // Reset form fields if needed
//       passwordForm.reset();
//     } catch (error) {
//       // Show error toast if something goes wrong
//       toast.error(error.message || "Failed to update password.");
//     }
//   };

//   return (
//     <div className="flex flex-row space-x-8">
//       {/* Profile Update Section */}
//       <div className="flex-1 p-12 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Update Profile</h2>
//         <p className="text-gray-600 mb-6">Update your account information here.</p>
//         <form onSubmit={profileForm.handleSubmit(onProfileSubmit)} className="space-y-4">
//           <div className="flex items-center space-x-4">
//             <label htmlFor="name" className="block text-gray-700 text-sm w-[30%]">Name</label>
//             <input
//               type="text"
//               id="name"
//               {...profileForm.register("name")}
//               className={`w-[70%] px-3 py-2 border ${
//                 profileForm.formState.errors.name ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//             />
//           </div>
//           {profileForm.formState.errors.name && (
//             <p className="text-red-500 text-sm">{profileForm.formState.errors.name.message}</p>
//           )}

//           <div className="flex items-center space-x-4">
//             <label htmlFor="email" className="block text-gray-700 text-sm w-[30%]">Email</label>
//             <input
//               type="email"
//               id="email"
//               {...profileForm.register("email")}
//               className={`w-[70%] px-3 py-2 border ${
//                 profileForm.formState.errors.email ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//             />
//           </div>
//           {profileForm.formState.errors.email && (
//             <p className="text-red-500 text-sm">{profileForm.formState.errors.email.message}</p>
//           )}

//           <button
//             type="submit"
//             className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//           >
//             Update Profile
//           </button>
//         </form>
//       </div>

//       {/* Password Change Section */}
//       <div className="flex-1 p-12 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Change Password</h2>
//         <p className="text-gray-600 mb-6">Update your password here. Please make sure it's secure.</p>
//         <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)} className="space-y-4">
//           {/* Old Password Field */}
//           <div className="relative flex items-center space-x-4">
//             <label htmlFor="oldPassword" className="block text-gray-700 text-sm w-[30%]">Old Password</label>
//             <input
//               type={showOldPassword ? "text" : "password"}
//               id="oldPassword"
//               {...passwordForm.register("oldPassword")}
//               className={`w-[70%] px-3 py-2 border ${
//                 passwordForm.formState.errors.oldPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//             />
//             <button
//               type="button"
//               className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//               onClick={() => setShowOldPassword(!showOldPassword)}
//               aria-label={showOldPassword ? "Hide password" : "Show password"}
//             >
//               {showOldPassword ? (
//                 <IoEyeOffOutline size={20} />
//               ) : (
//                 <IoEyeOutline size={20} />
//               )}
//             </button>
//           </div>

//           {/* New Password Field */}
//           <div className="relative flex items-center space-x-4">
//             <label htmlFor="newPassword" className="block text-gray-700 text-sm w-[30%]">New Password</label>
//             <input
//               type={showNewPassword ? "text" : "password"}
//               id="newPassword"
//               {...passwordForm.register("newPassword")}
//               className={`w-[70%] px-3 py-2 border ${
//                 passwordForm.formState.errors.newPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//             />
//             <button
//               type="button"
//               className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//               onClick={() => setShowNewPassword(!showNewPassword)}
//               aria-label={showNewPassword ? "Hide password" : "Show password"}
//             >
//               {showNewPassword ? (
//                 <IoEyeOffOutline size={20} />
//               ) : (
//                 <IoEyeOutline size={20} />
//               )}
//             </button>
//           </div>

//           {/* Confirm New Password Field */}
//           <div className="relative flex items-center space-x-4">
//             <label htmlFor="confirmPassword" className="block text-gray-700 text-sm w-[30%]">Confirm New Password</label>
//             <input
//               type={showConfirmPassword ? "text" : "password"}
//               id="confirmPassword"
//               {...passwordForm.register("confirmPassword")}
//               className={`w-[70%] px-3 py-2 border ${
//                 passwordForm.formState.errors.confirmPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4] text-sm`}
//             />
//             <button
//               type="button"
//               className="absolute inset-y-0 right-3 flex items-center text-gray-600 focus:outline-none"
//               onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//               aria-label={showConfirmPassword ? "Hide password" : "Show password"}
//             >
//               {showConfirmPassword ? (
//                 <IoEyeOffOutline size={20} />
//               ) : (
//                 <IoEyeOutline size={20} />
//               )}
//             </button>
//           </div>

//           <button
//             type="submit"
//             className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//           >
//             Change Password
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }
// import React from "react";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { useForm } from "react-hook-form";
// import * as z from "zod";
// import { toast } from "react-toastify";

// const profileFormSchema = z.object({
//   name: z.string().min(3, {
//     message: "Name must be at least 3 characters.",
//   }),
//   email: z.string().email({
//     message: "Please enter a valid email address.",
//   }),
// });

// const passwordFormSchema = z.object({
//   oldPassword: z.string().min(8, {
//     message: "Password must be at least 8 characters.",
//   }),
//   newPassword: z
//     .string()
//     .min(8, {
//       message: "Password must be at least 8 characters.",
//     })
//     .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, {
//       message:
//         "Password must contain at least one uppercase letter, one lowercase letter, and one special character.",
//     }),
//   confirmPassword: z.string(),
// }).refine((data) => data.newPassword === data.confirmPassword, {
//   message: "Passwords don't match",
//   path: ["confirmPassword"],
// });

// export default function AccountInfo() {
//   const profileForm = useForm({
//     resolver: zodResolver(profileFormSchema),
//     defaultValues: {
//       name: "John Doe",
//       email: "john@example.com",
//     },
//   });

//   const passwordForm = useForm({
//     resolver: zodResolver(passwordFormSchema),
//     defaultValues: {
//       oldPassword: "",
//       newPassword: "",
//       confirmPassword: "",
//     },
//   });

//   function onProfileSubmit(data) {
//     toast({
//       title: "Profile updated",
//       description: "Your profile information has been updated.",
//     });
//   }

//   function onPasswordSubmit(data) {
//     toast({
//       title: "Password changed",
//       description: "Your password has been successfully changed.",
//     });
//   }

//   return (
//     <div className="flex flex-row space-x-8">
//       {/* Profile Update Section */}
//       <div className="flex-1 p-6 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Update Profile</h2>
//         <p className="text-gray-600 mb-6">Update your account information here.</p>
//         <form onSubmit={profileForm.handleSubmit(onProfileSubmit)} className="space-y-4">
//           <div>
//             <label htmlFor="name" className="block text-gray-700 mb-2">Name</label>
//             <input
//               type="text"
//               id="name"
//               {...profileForm.register("name")}
//               className={`w-full px-3 py-2 border ${
//                 profileForm.formState.errors.name ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {profileForm.formState.errors.name && (
//               <p className="text-red-500 text-sm">{profileForm.formState.errors.name.message}</p>
//             )}
//           </div>

//           <div>
//             <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
//             <input
//               type="email"
//               id="email"
//               {...profileForm.register("email")}
//               className={`w-full px-3 py-2 border ${
//                 profileForm.formState.errors.email ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {profileForm.formState.errors.email && (
//               <p className="text-red-500 text-sm">{profileForm.formState.errors.email.message}</p>
//             )}
//           </div>

//           <button
//             type="submit"
//             className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//           >
//             Update Profile
//           </button>
//         </form>
//       </div>

//       {/* Password Change Section */}
//       <div className="flex-1 p-6 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Change Password</h2>
//         <p className="text-gray-600 mb-6">Update your password here. Please make sure it's secure.</p>
//         <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)} className="space-y-4">
//           <div>
//             <label htmlFor="oldPassword" className="block text-gray-700 mb-2">Old Password</label>
//             <input
//               type="password"
//               id="oldPassword"
//               {...passwordForm.register("oldPassword")}
//               className={`w-full px-3 py-2 border ${
//                 passwordForm.formState.errors.oldPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {passwordForm.formState.errors.oldPassword && (
//               <p className="text-red-500 text-sm">{passwordForm.formState.errors.oldPassword.message}</p>
//             )}
//           </div>

//           <div>
//             <label htmlFor="newPassword" className="block text-gray-700 mb-2">New Password</label>
//             <input
//               type="password"
//               id="newPassword"
//               {...passwordForm.register("newPassword")}
//               className={`w-full px-3 py-2 border ${
//                 passwordForm.formState.errors.newPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {passwordForm.formState.errors.newPassword && (
//               <p className="text-red-500 text-sm">{passwordForm.formState.errors.newPassword.message}</p>
//             )}
//           </div>

//           <div>
//             <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">Confirm New Password</label>
//             <input
//               type="password"
//               id="confirmPassword"
//               {...passwordForm.register("confirmPassword")}
//               className={`w-full px-3 py-2 border ${
//                 passwordForm.formState.errors.confirmPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {passwordForm.formState.errors.confirmPassword && (
//               <p className="text-red-500 text-sm">{passwordForm.formState.errors.confirmPassword.message}</p>
//             )}
//           </div>

//           <button
//             type="submit"
//             className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//           >
//             Change Password
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }
// import React from "react";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { useForm } from "react-hook-form";
// import * as z from "zod";
// import { toast } from "react-toastify";

// const profileFormSchema = z.object({
//   name: z.string().min(3, {
//     message: "Name must be at least 3 characters.",
//   }),
//   email: z.string().email({
//     message: "Please enter a valid email address.",
//   }),
// });

// const passwordFormSchema = z.object({
//   oldPassword: z.string().min(8, {
//     message: "Password must be at least 8 characters.",
//   }),
//   newPassword: z
//     .string()
//     .min(8, {
//       message: "Password must be at least 8 characters.",
//     })
//     .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, {
//       message:
//         "Password must contain at least one uppercase letter, one lowercase letter, and one special character.",
//     }),
//   confirmPassword: z.string(),
// }).refine((data) => data.newPassword === data.confirmPassword, {
//   message: "Passwords don't match",
//   path: ["confirmPassword"],
// });

// export default function AccountInfo() {


//   const profileForm = useForm({
//     resolver: zodResolver(profileFormSchema),
//     defaultValues: {
//       name: "John Doe",
//       email: "john@example.com",
//     },
//   });

//   const passwordForm = useForm({
//     resolver: zodResolver(passwordFormSchema),
//     defaultValues: {
//       oldPassword: "",
//       newPassword: "",
//       confirmPassword: "",
//     },
//   });

//   function onProfileSubmit(data) {
//     toast({
//       title: "Profile updated",
//       description: "Your profile information has been updated.",
//     });
//   }

//   function onPasswordSubmit(data) {
//     toast({
//       title: "Password changed",
//       description: "Your password has been successfully changed.",
//     });
//   }

//   return (
//     <div className="space-y-6">
//       <div className="p-6 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Update Profile</h2>
//         <p className="text-gray-600 mb-6">Update your account information here.</p>
//         <form onSubmit={profileForm.handleSubmit(onProfileSubmit)} className="space-y-4">
//           <div>
//             <label htmlFor="name" className="block text-gray-700 mb-2">Name</label>
//             <input
//               type="text"
//               id="name"
//               {...profileForm.register("name")}
//               className={`w-full px-3 py-2 border ${
//                 profileForm.formState.errors.name ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {profileForm.formState.errors.name && (
//               <p className="text-red-500 text-sm">{profileForm.formState.errors.name.message}</p>
//             )}
//           </div>

//           <div>
//             <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
//             <input
//               type="email"
//               id="email"
//               {...profileForm.register("email")}
//               className={`w-full px-3 py-2 border ${
//                 profileForm.formState.errors.email ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {profileForm.formState.errors.email && (
//               <p className="text-red-500 text-sm">{profileForm.formState.errors.email.message}</p>
//             )}
//           </div>

//           <button
//             type="submit"
//             className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//           >
//             Update Profile
//           </button>
//         </form>
//       </div>

//       <div className="p-6 bg-white shadow rounded-md">
//         <h2 className="text-xl font-semibold text-[#373cc4] mb-1">Change Password</h2>
//         <p className="text-gray-600 mb-6">Update your password here. Please make sure it's secure.</p>
//         <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)} className="space-y-4">
//           <div>
//             <label htmlFor="oldPassword" className="block text-gray-700 mb-2">Old Password</label>
//             <input
//               type="password"
//               id="oldPassword"
//               {...passwordForm.register("oldPassword")}
//               className={`w-full px-3 py-2 border ${
//                 passwordForm.formState.errors.oldPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {passwordForm.formState.errors.oldPassword && (
//               <p className="text-red-500 text-sm">{passwordForm.formState.errors.oldPassword.message}</p>
//             )}
//           </div>

//           <div>
//             <label htmlFor="newPassword" className="block text-gray-700 mb-2">New Password</label>
//             <input
//               type="password"
//               id="newPassword"
//               {...passwordForm.register("newPassword")}
//               className={`w-full px-3 py-2 border ${
//                 passwordForm.formState.errors.newPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {passwordForm.formState.errors.newPassword && (
//               <p className="text-red-500 text-sm">{passwordForm.formState.errors.newPassword.message}</p>
//             )}
//           </div>

//           <div>
//             <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">Confirm New Password</label>
//             <input
//               type="password"
//               id="confirmPassword"
//               {...passwordForm.register("confirmPassword")}
//               className={`w-full px-3 py-2 border ${
//                 passwordForm.formState.errors.confirmPassword ? "border-red-500" : "border-gray-300"
//               } rounded-md focus:outline-none focus:ring-2 focus:ring-[#373cc4]`}
//             />
//             {passwordForm.formState.errors.confirmPassword && (
//               <p className="text-red-500 text-sm">{passwordForm.formState.errors.confirmPassword.message}</p>
//             )}
//           </div>

//           <button
//             type="submit"
//             className="px-4 py-2 bg-[#373cc4] text-white rounded-md hover:bg-[#2d30a3] focus:outline-none focus:ring-2 focus:ring-[#373cc4]"
//           >
//             Change Password
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }