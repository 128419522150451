// src/components/Nav.jsx

import React from 'react';
import { HomeIcon } from '@heroicons/react/20/solid';
import { useLocation, Link } from 'react-router-dom';

// Utility function to convert strings to Title Case
function toTitleCase(name) {
  return name
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const Nav = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(Boolean);

  return (
    <div className="py-6">
      <ol role="list" className="flex text-sm items-center space-x-4">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return (
            <li key={name}>
              <div className="flex items-center">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                {isLast ? (
                  <span className="ml-4 text-sm font-medium text-gray-500">
                    {toTitleCase(name)}
                  </span>
                ) : (
                  <Link
                    to={routeTo}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {toTitleCase(name)}
                  </Link>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Nav;