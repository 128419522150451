import api from "./api";

// Create a new subscription
export const createSubscription = async (userId, planId, paymentMethodId) => {
  try {
    console.log(" userId, planId, paymentMethodId:,", userId, planId, paymentMethodId)
    const response = await api.post("/subscriptions", { userId, planId, paymentMethodId });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Get subscription details for a user
export const getSubscription = async (userId) => {
  try {
    const response = await api.get(`/subscriptions/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Get subscription details for a user
export const getSubscriptionInformation = async (customerId) => {
    try {
      const response = await api.get(`/subscriptions/card-information/${customerId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };
// Cancel an active subscription
export const cancelSubscription = async (subscriptionId) => {
  try {
    const response = await api.delete(`/subscriptions/${subscriptionId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Update payment method for a subscription
export const updatePaymentMethod = async (subscriptionId, paymentMethodId) => {
  try {
    const response = await api.put(`/subscriptions/${subscriptionId}/payment-method`, { paymentMethodId });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Get all available subscription plans
export const getAllPlans = async () => {
  try {
    const response = await api.get("/plans");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Get a specific subscription plan
export const getPlan = async (planId) => {
  try {
    const response = await api.get(`/plans/${planId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Create a new subscription plan
export const createPlan = async (name, price, features) => {
  try {
    const response = await api.post("/plans", { name, price, features });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Get invoices for a user
export const getInvoices = async (userId) => {
  try {
    const response = await api.get(`/invoices/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Get details of a specific invoice
export const getInvoiceDetails = async (invoiceId) => {
  try {
    const response = await api.get(`/invoices/detail/${invoiceId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};