import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { updatePaymentMethod } from "../utils/apis/subscriptionService";

const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#a0aec0",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const UpdateCard = ({ subscriptionId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    try {
      // Create a payment method with the card details
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        toast.error(error.message);
        setLoading(false);
        return;
      }

      // Send paymentMethod.id to your backend to update the payment method for the subscription
      const response = await updatePaymentMethod(subscriptionId, paymentMethod.id);

      if (response.success) {
        toast.success("Card updated successfully!");
        cardElement.clear();
      } else {
        toast.error("Failed to update card.");
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred. Please try again.");
    }

    setLoading(false);
  };

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg border border-gray-200">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Update Payment Method</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-600 mb-2">Credit Card Information</label>
          <CardElement options={cardStyle} className="p-3 border border-gray-300 rounded-md" />
        </div>
        
        <button
          type="submit"
          disabled={!stripe || loading}
          className={`w-full py-2 px-4 rounded-md text-white text-lg font-semibold focus:outline-none ${
            loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
          }`}
        >
          {loading ? "Updating..." : "Update Card"}
        </button>
      </form>
    </div>
  );
};

export default UpdateCard;